import { initialState } from './GlobalFormStateContext'
import { RESET, SET_FIELD } from '../../globalConfig/common/ConstantData'

export function globalReducer(draft, action) {
  switch (action.type) {
    case SET_FIELD: {
      action.payload.forEach((field) => {
        draft[field.id] = field.value
      })
      return
    }
    case RESET: {
      return initialState
    }
    default:
      return
  }
}
