import { Card, Grid, Layout } from '@enterprise-ui/canvas-ui-react'

import { SectionHeader } from '../globalComponents/SectionHeader'

export const ErrorContent = ({ error }) => {
  return (
    <>
      <SectionHeader pageHeading="Error" />
      <Layout.Body data-testid="layoutWithErrorContent">
        <Card>
          <Grid.Container>
            <Grid.Item>
              <p>Your error content: {error.message}</p>
            </Grid.Item>
          </Grid.Container>
        </Card>
      </Layout.Body>
      <Layout.Rail data-testid="layoutWithErrorRailContent">
        <Card>
          <Grid.Container>
            <Grid.Item>
              <p>Your help content</p>
            </Grid.Item>
          </Grid.Container>
        </Card>
      </Layout.Rail>
    </>
  )
}
