import React from 'react'

const SearchStateContext = React.createContext(undefined)

SearchStateContext.displayName = 'SearchStateContext'

const SearchDispatchContext = React.createContext(undefined)

SearchStateContext.displayName = 'SearchDispatchContext'

export { SearchDispatchContext, SearchStateContext }
