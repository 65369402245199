import { BrowserRouter as Router } from 'react-router-dom'
import '@enterprise-ui/canvas-ui-css'
import '@enterprise-ui/canvas-ui-css-autocomplete'
import { HelveticaForTarget } from '@enterprise-ui/component-font'
import { AuthProvider } from '@praxis/component-auth'
import './globalStyles/customStyles.scss'
import { Main } from './views/Main'
import { EnvProvider } from '@praxis/component-runtime-env'
import { UserProfileProvider } from './globalComponents/UserProfileProvider'
import { GlobalFormProvider } from './views/Context/GlobalFormStateContext'
import SessionCookieHandler from './views/SessionCookieHandler'

const App = () => {
  return (
    <EnvProvider
      configPath={
        process.env.NODE_ENV === 'development'
          ? '/config.json'
          : '/app-environment'
      }
    >
      {(env) => {
        return (
          <AuthProvider
            {...env.env.auth}
            logoutRedirect={`${window.location.origin}`}
          >
            <SessionCookieHandler>
              <HelveticaForTarget variants={['n4', 'n5', 'n7']} />
              <Router>
                <UserProfileProvider>
                  <GlobalFormProvider>
                    <Main />
                  </GlobalFormProvider>
                </UserProfileProvider>
              </Router>
            </SessionCookieHandler>
          </AuthProvider>
        )
      }}
    </EnvProvider>
  )
}

export default App
