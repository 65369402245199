import React from 'react'
import { useImmerReducer } from 'use-immer'
import { globalReducer } from './GlobalReducer'

export const initialState = {
  vendorNumber: '',
  fiscalYear: '',
  isLocationShowable: false,
  isLocAttributesShowable: false,
  yearOptions: [],
  locationOptions: [],
  expenseTypeOptions: [],
  expensePool: [],
  contractData: [],
  contractOptinons: [],
  budgetExpenses: [],
  isContractShowable: false,
  isShowErrorModal: false,
  isCreateBudgetExpenseShowable: false,
  isEditBudgetExpenseShowable: false,
  isDeleteBudgetExpenseShowable: false,
  deletedBudgetExpense: {},
  deleteBudgetExpenses: [],
  editedBudgetExpense: {},
  isBudgetShowable: false,
  loadingLocationDetails: false,
  loadingVendorData: false,
  isVendShowable: false,
  isCancel: false,
  name: '',
  isSubmit: false,
  creatingBudget: false,
  budgetCreatedFlag: false,
  viewAttachmentStatus: false,
  submitStatuses: [],
  errorMsg: '',
  grandTotalExpenseAmount: 0,
  totalPrsAmount: 0,
  totalAdminAmount: 0,
  totalMonthlyAmount: 0,
  totalAnnualAmount: 0,
  // new values for tm budget view
  budgetData: {},
  budgetDataLoaded: false,
  hasRoleAndWorkflowMatch: false,
  budgetWorkflow: [],
  showWorkflow: false,
  activityDescr: '',
  isBudgetChanged: false,
  isBudgetDeleted: false,
  showAssignModal: false,
  showUserQueryModal: false,
  showReopenModal: false,
  isDisplayOnlyBudget: false,
  vendorDataOptions: [],
  vendorDetails: [],
  savedAttachments: [],
  downloadingAttachment: undefined,
  attachmentsError: false,
  toastHeading: '',
  toastMessage: '',
  fileUrl: '',
  setAttachmentView: false,
  isAttachmentNotViewable: false,
  mediaType: '',
  fileName: '',
  format: false,
  viewAttachment: undefined,
  fileRowIndex: undefined,

  // Actuals
  actualExpenses: [],
  isCreateActualExpenseShowable: false,
  isEditActualExpenseShowable: false,
  isDeleteActualExpenseShowable: false,
  deletedActualExpense: {},
  deleteActualExpenses: [],
  editedActualExpense: {},
  isActualShowable: false,
  creatingActual: false,
  actualCreatedFlag: false,
  actualData: {},
  actualDataLoaded: false,
  isActualChanged: false,
  isActualDeleted: false,
  isDisplayOnlyActual: false,
  taxRate: 0,
  taxAmount: 0,
  totalDueAmount: 0,
  priorPaymentAmount: 0,
  priorTaxPaymentAmount: 0,
  finalDueAmount: 0,
}

const GlobalFormStateContext = React.createContext(undefined)
const GlobalFormDispatchContext = React.createContext(undefined)

const GlobalFormProvider = (props) => {
  const [state, dispatch] = useImmerReducer(globalReducer, initialState)

  return (
    <GlobalFormStateContext.Provider value={state}>
      <GlobalFormDispatchContext.Provider value={dispatch}>
        {props.children}
      </GlobalFormDispatchContext.Provider>
    </GlobalFormStateContext.Provider>
  )
}

function useGlobalFormState() {
  const context = React.useContext(GlobalFormStateContext)
  if (context === undefined) {
    throw new Error(
      'useGlobalFormState must be used within a GlobalFormStateContext'
    )
  }
  return context
}
function useGlobalFormDispatch() {
  const context = React.useContext(GlobalFormDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useGlobalFormDispatch must be used within a GlobalFormDispatchContext'
    )
  }
  return context
}

function useGlobalForm() {
  return [useGlobalFormState(), useGlobalFormDispatch()]
}

export {
  GlobalFormStateContext,
  GlobalFormDispatchContext,
  GlobalFormProvider,
  useGlobalForm,
  useGlobalFormDispatch,
  useGlobalFormState,
}
