import { useEffect, useState, useRef } from 'react'
import { GridItem, Button } from '@enterprise-ui/canvas-ui-react'
import { CSVLink } from 'react-csv'
import { DownloadIcon, EnterpriseIcon } from '@enterprise-ui/icons'
import { useGlobalForm } from '../../Context/GlobalFormStateContext'
import { editButtons } from '../../Common/CamsDivComponent'
import { useAuth } from '@praxis/component-auth'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { BudgetSettlementReportToPDF } from '../PDFExports/BudgetSettlementReportToPDF'

const DownloadBudgetSettlementReport = () => {
  const [downloadData, setDownloadData] = useState([])
  const { session } = useAuth()
  const csvLink = useRef(null)
  // eslint-disable-next-line
  const [state, dispatch] = useGlobalForm()

  useEffect(() => {
    if (downloadData.length > 0) {
      setTimeout(() => {
        csvLink?.current?.link.click()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadData])

  const formatBudgetData = async () => {
    let formattedData = []

    // Add headers for Header Details
    formattedData.push([
      'Fiscal Year',
      'Contract No',
      'Location Id',
      'Location Name',
      'Vendor #',
      'Vendor Name',
      'Total Submitted Expense $',
      'Target Share - Annual $',
      'Target Share - Monthly $',
    ])

    // Add data for Header Details
    formattedData.push([
      state.budgetData?.year,
      state.budgetData?.contract_no,
      state.budgetData?.location_id,
      state.budgetData?.loc_name,
      state.budgetData?.vendor_no,
      state.budgetData?.vendor_name,
      state.budgetData?.total_amount,
      state.budgetData?.tgt_annual_amount,
      state.budgetData?.tgt_monthly_amount,
    ])

    // Add empty row
    formattedData.push([])

    // Add headers for Expense Details
    formattedData.push([
      'Expense Type Name',
      'Expense Type',
      'Total Expense Amount $',
      'PRS %',
      'PRS Amount $',
      'Admin Fee %',
      'Admin Amount $',
      'Annual Amount $',
      'Target Share - Monthly $',
    ])

    for (let i = 0; i < state.budgetData.budget_expenses.length; i++) {
      formattedData.push([
        state.budgetData?.budget_expenses[i].expense_type_name,
        state.budgetData?.budget_expenses[i].expense_type,
        state.budgetData?.budget_expenses[i].total_expense_amount,
        state.budgetData?.budget_expenses[i].prs,
        state.budgetData?.budget_expenses[i].prs_amount,
        state.budgetData?.budget_expenses[i].admin_fee,
        state.budgetData?.budget_expenses[i].admin_amount,
        state.budgetData?.budget_expenses[i].annual_amount,
        state.budgetData?.budget_expenses[i].monthly_amount,
      ])
    }

    // Add Summary Data
    formattedData.push([
      '',
      'TOTAL',
      state.grandTotalExpenseAmount,
      '',
      state.totalPrsAmount,
      '',
      state.totalAdminAmount,
      state.totalAnnualAmount,
      state.totalMonthlyAmount,
    ])
    setDownloadData(formattedData)
  }

  return (
    <>
      <GridItem>
        {/* Export to CSV option */}
        <Button
          type="primary"
          css={editButtons}
          className="hc-ml-xs"
          onClick={async () => {
            formatBudgetData()
          }}
        >
          <EnterpriseIcon icon={DownloadIcon} /> &nbsp;Export to CSV
        </Button>
        {/* Export to PDF option */}
        <PDFDownloadLink
          document={
            <BudgetSettlementReportToPDF
              title={'CAM Payable Budget Report'}
              data={state}
              session={session}
            />
          }
          fileName={`${state.budgetData?.location_id}-${state.budgetData?.year}-${state.budgetData?.contract_no}-payable-budget.pdf`}
        >
          <Button type="primary" css={editButtons} className="hc-ml-xs">
            <EnterpriseIcon icon={DownloadIcon} /> &nbsp;Export to PDF
          </Button>
        </PDFDownloadLink>
      </GridItem>
      {downloadData.length > 0 && (
        <CSVLink
          data={downloadData}
          filename={`${state.budgetData?.location_id}-${state.budgetData?.year}-${state.budgetData?.contract_no}-payable-budget.csv`}
          className="hidden"
          ref={csvLink}
          target="_blank"
        />
      )}
    </>
  )
}

export default DownloadBudgetSettlementReport
