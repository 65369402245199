import { InputCheckbox } from '@enterprise-ui/canvas-ui-react'

export const FilterCheckBox = ({ id, label, onChange, value, checked }) => {
  return (
    <InputCheckbox
      id={id}
      label={<div className="hc-ws-no-wrap">{label}</div>}
      onChange={onChange}
      value={value}
      checked={checked}
    />
  )
}
