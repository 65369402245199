import { Grid, Button, Heading } from '@enterprise-ui/canvas-ui-react'
import { useState, useMemo } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import { AddActualExpenses } from './AddActualExpenses'
import { EditActualExpenses } from './EditActualExpenses'
import { DeleteActualExpenses } from './DeleteActualExpenses'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import EnterpriseIcon, { PencilIcon, TrashIcon } from '@enterprise-ui/icons'
import { SET_FIELD } from '../../globalConfig/common/ConstantData'

export const ActualExpenses = ({ expenseheadingText }) => {
  const handleAddExpensePool = () => {
    setAddActualExpense(true)
  }
  const [addActualExpense, setAddActualExpense] = useState(false)
  const [state, dispatch] = useGlobalForm()

  const [rowData] = [state.actualExpenses]

  const percentageFormatter = (params) => {
    return params.value.toFixed(2) + '%'
  }

  const currencyFormatter = (params) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(Number(params.value))
  }

  const [columnDefs] = useState([
    {
      headerName: 'Edit ',
      cellStyle: {
        textAlign: 'center',
      },
      maxWidth: 65,
      filter: false,
      cellRenderer: (props) => {
        return (
          <Button
            iconOnly
            type="ghost"
            aria-label="Edit Icon"
            disabled={state.isDisplayOnlyActual}
            onClick={() => {
              dispatch({
                type: SET_FIELD,
                payload: [
                  { id: 'isEditActualExpenseShowable', value: true },
                  { id: 'editedActualExpense', value: props.data },
                ],
              })
            }}
          >
            <EnterpriseIcon icon={PencilIcon} />
          </Button>
        )
      },
    },
    {
      headerName: 'Delete ',
      cellStyle: {
        textAlign: 'center',
      },
      maxWidth: 65,
      filter: false,
      cellRenderer: (props) => {
        return (
          <Button
            iconOnly
            type="ghost"
            aria-label="Delete Icon"
            disabled={state.isDisplayOnlyActual}
            onClick={() => {
              dispatch({
                type: SET_FIELD,
                payload: [
                  { id: 'isDeleteActualExpenseShowable', value: true },
                  { id: 'deletedActualExpense', value: props.data },
                ],
              })
            }}
          >
            <EnterpriseIcon icon={TrashIcon} />
          </Button>
        )
      },
    },
    {
      headerName: 'Expense Type Name',
      field: 'expense_type_name',
      width: 460,
    },
    {
      headerName: 'Expense Type',
      field: 'expense_type',
      width: 125,
    },
    {
      headerName: 'Total Expense',
      field: 'total_expense_amount',
      width: 125,
      valueFormatter: currencyFormatter,
    },
    {
      headerName: 'PRS',
      field: 'prs',
      width: 70,
      valueFormatter: percentageFormatter,
    },
    {
      headerName: 'PRS Amount',
      field: 'prs_amount',
      width: 110,
      valueFormatter: currencyFormatter,
    },
    {
      headerName: 'Admin Fee',
      field: 'admin_fee',
      width: 70,
      valueFormatter: percentageFormatter,
    },
    {
      headerName: 'Admin Amount',
      field: 'admin_amount',
      width: 110,
      valueFormatter: currencyFormatter,
    },
    {
      headerName: 'Annual Amount',
      field: 'annual_amount',
      width: 110,
      valueFormatter: currencyFormatter,
    },
  ])

  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      wrapText: true,
      autoHeight: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    }),
    []
  )

  return (
    <Grid.Container className="hc-pt-expanded hc-ml-min">
      <Heading size={6}>{expenseheadingText} </Heading>
      <Grid.Container justify="center" className="hc-ml-min hc-mr-expanded">
        <div
          className="ag-theme-balham"
          style={{ height: '100%', width: '100%' }}
        >
          <Grid.Container justify="flex-end">
            <Grid.Item className="hc-pb-lg">
              <Button
                type="secondary"
                disabled={state.isDisplayOnlyActual}
                onClick={handleAddExpensePool}
              >
                Add Expenses
              </Button>
            </Grid.Item>
          </Grid.Container>
          <AgGridReact
            rowData={rowData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            pagination={true}
            paginationPageSize={10}
            rowHeight="40"
            domLayout="autoHeight"
          ></AgGridReact>
        </div>
        <AddActualExpenses
          addActualExpenseVisible={addActualExpense}
          setAddActualExpenseVisible={setAddActualExpense}
        ></AddActualExpenses>
        <EditActualExpenses headingText="Edit Actual Expense"></EditActualExpenses>
        <DeleteActualExpenses headingText="Delete Actual Expense"></DeleteActualExpenses>
      </Grid.Container>
    </Grid.Container>
  )
}
