import { createContext, useState } from 'react'
import axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'

export const AdminContext = createContext({
  fetchExpensePool: () => {},
  fetchContractMetadata: () => {},
})

export const AdminProvider = ({ children }) => {
  const env = useEnv()
  const [expenseData, setExpenseData] = useState([])
  const [loadingExpensePool, setLoadingExpensePool] = useState(true)
  const [contractMetadata, setContractMetadata] = useState([])
  const [loadingContractMetadata, setLoadingContractMetadata] = useState(true)
  const makeToast = ToastProvider.useToaster()

  async function fetchExpensePool() {
    setExpenseData([])
    setLoadingExpensePool(true)
    await axios
      .get(`${env.camsCoreApiUrl}/expense_pool?key=${env.apiKey}`)
      .then((res) => {
        setExpenseData(res.data)
      })
      .catch((error) => {
        console.log('Error when fetching expense pool data in context', error)
        makeToast({
          type: 'error',
          heading: 'Network Error',
          message: 'Something went wrong. Please try again later.',
        })
      })
    setLoadingExpensePool(false)
  }

  async function fetchContractMetadata() {
    setContractMetadata([])
    setLoadingContractMetadata(true)
    await axios
      .get(`${env.camsCoreApiUrl}/contract_metadata?key=${env.apiKey}`)
      .then((res) => {
        setContractMetadata(res.data)
      })
      .catch((error) => {
        console.log('Error when fetching contract metadata in context', error)
        makeToast({
          type: 'error',
          heading: 'Network Error',
          message: 'Something went wrong. Please try again later.',
        })
      })
    setLoadingContractMetadata(false)
  }

  return (
    <AdminContext.Provider
      value={{
        expenseData,
        setExpenseData,
        fetchExpensePool,
        loadingExpensePool,
        setLoadingExpensePool,
        contractMetadata,
        setContractMetadata,
        fetchContractMetadata,
        loadingContractMetadata,
        setLoadingContractMetadata,
      }}
    >
      {children}
    </AdminContext.Provider>
  )
}
