import { BudgetStatus } from '../../globalConfig/common/ConstantData'
export function getStatusColor(status) {
  if (
    status === BudgetStatus.IN_PROGRESS ||
    status === BudgetStatus.MORE_INFO_REQUIRED ||
    status === BudgetStatus.REOPENED ||
    status === BudgetStatus.SUBMITTED_FOR_APPROVAL
  ) {
    return 'alert'
  } else if (status === BudgetStatus.DENIED) {
    return 'error'
  } else if (
    status === BudgetStatus.APPROVED ||
    status === BudgetStatus.APPROVED_BY_SITEOPS
  ) {
    return 'success'
  }

  return 'default'
}

export function getChipClassNames(docType) {
  if (docType === 'Budget') {
    return 'hc-bg-teal'
  } else {
    return 'hc-bg-pink'
  }
}

export function addEscapeChar(value) {
  const specialChars = ['/', '-', '=']
  let id = value

  specialChars.forEach((char) => {
    if (id.includes(char)) {
      id = id.replaceAll(char, '\\' + char)
    }
  })
  return id
}
