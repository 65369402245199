import React, { useState } from 'react'

const AttachmentContext = React.createContext(undefined)

const AttachmentContextProvider = (props) => {
  const [uploadedAttachments, setUploadedAttachments] = useState([])

  return (
    <AttachmentContext.Provider
      value={{
        uploadedAttachments,
        setUploadedAttachments,
      }}
    >
      {props.children}
    </AttachmentContext.Provider>
  )
}
export default AttachmentContextProvider

export { AttachmentContext }
