/** @jsxImportSource @emotion/react */
import axios from 'axios'
import { useEffect, useState, useRef, useContext } from 'react'
import {
  Grid,
  Button,
  Form,
  LayoutBody,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { useEnv } from '@praxis/component-runtime-env'
import { useGlobalForm } from '../../Context/GlobalFormStateContext'
import {
  BudgetStatus,
  SET_FIELD,
  RESET,
  BUDGET_STATUS,
  Workflow,
  ASSIGNED_TO_TGT,
  REOPEN,
  ATTACHMENTS,
} from '../../../globalConfig/common/ConstantData'
import { ViewYearAndDocType } from '../../Common/BudgetView/ViewYearAndDocType'
import { ViewVendorDetails } from '../../Common/BudgetView/ViewVendorDetails'
import { ViewWorkflowDetails } from '../../Common/BudgetView/ViewWorkflowDetails'
import { ViewDates } from '../../Common/BudgetView/ViewDates'
import { ViewReopenDetails } from '../../Common/BudgetView/ViewReopenDetails'
import { ViewLocationDetails } from '../../Common/BudgetView/ViewLocationDetails'
import { ViewContractDetails } from '../../Common/BudgetView/ViewContractDetails'
import { useProfile } from '../../../globalComponents/UserProfileProvider'
import { editButtons } from '../../Common/CamsDivComponent'
import * as Yup from 'yup'
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik'
import { ViewWorkflowModal } from '../../Common/BudgetView/ViewWorkflowModal'
import { NotesTab } from '../../Common/BudgetView/NotesTab'
import { GenericFunctions } from '../../Utils/GenericFunctions'
import { BudgetExpenses } from '../../Common/BudgetExpenses'
import { CancelModal } from '../../Common/CancelModal'
import { ViewUserQueryModal } from '../../Common/BudgetView/ViewUserQueryModal'
import { ViewReopenModal } from '../../Common/BudgetView/ViewReopenModal'
import { LoadingSpinner } from '../../../globalComponents/LoadingSpinner'
import Attachments from '../../Attachment/Attachments'
import { useAttachmentRequest } from '../../hooks/useAttachmentRequest'
import { AttachmentContext } from '../../Context/AttachmentContext'
import ViewSaveTimelineModal from '../../Common/ViewSaveTimeLineModal'
import DownloadAttachments from '../../Common/BudgetView/DownloadAttachments'
import BudgetAmountDetails from '../../Common/BudgetAmountDetails'
import { BreadCrumb } from '../../Common/BreadCrumb'
import moment from 'moment'
import { useNavigate } from 'react-router'

export const BPBudgetView = (props) => {
  const env = useEnv()
  const [state, dispatch] = useGlobalForm()
  const { userProfile } = useProfile()
  const [budgetUpdate, setBudgetUpdate] = useState('')
  const userName = userProfile.fullName
  const userId = userProfile.userId
  const showActivity = useRef(null)
  const makeToast = ToastProvider.useToaster()
  const navigate = useNavigate()
  const {
    addAuditEntryData,
    findRecentAnalyst,
    findPriorWorkflow,
    getExpenseTypes,
    getBudgetAmountDetails,
  } = GenericFunctions()

  const { uploadAttachments, getAttachmentForReqId } = useAttachmentRequest()
  const { uploadedAttachments } = useContext(AttachmentContext)

  useEffect(() => {
    fetchBudget(props.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id])

  useEffect(() => {
    getExpenseTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchAttachmentMetaData = async (requestId) => {
    await getAttachmentForReqId(requestId, 'Budget')
      .then((data) => {
        dispatch({
          type: SET_FIELD,
          payload: [
            {
              id: 'savedAttachments',
              value: data?.map(function (attachmentData) {
                return {
                  fileName: attachmentData.file_name,
                  documentType: attachmentData.document_type,
                  attachmentId: attachmentData.attachment_id,
                  createdBy: attachmentData.created_by,
                  createdTimestamp: moment(
                    attachmentData?.created_timestamp
                  ).format('MM/DD/YYYY'),
                }
              }),
            },
          ],
        })
      })
      .catch((error) => {
        console.log('Error fetching attachment metadata', error)
      })
  }

  async function fetchBudget(budgetId) {
    // initialize budget expense before fetching budget data
    dispatch({
      type: SET_FIELD,
      payload: [
        { id: 'budgetExpenses', value: [] },
        { id: 'budgetDataLoaded', value: false },
      ],
    })
    await axios
      .get(`${env.camsCoreApiUrl}/budget/${budgetId}?key=${env.apiKey}`)
      .then((res) => {
        findDisplayOnly(res.data)
        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'budgetData', value: res.data },
            { id: 'budgetDataLoaded', value: true },
            { id: 'budgetExpenses', value: res.data.budget_expenses },
          ],
        })
        fetchAttachmentMetaData(budgetId)
        getExpenseTypes()
      })
      .catch((error) => {
        console.log('Error when fetching budget', error)
        dispatch({
          type: SET_FIELD,
          payload: [{ id: 'budgetDataLoaded', value: true }],
        })
        makeToast({
          type: 'error',
          heading: 'Network Error',
          message: 'Something went wrong. Please try again later',
        })
        navigate(`/`)
      })
  }

  async function DeleteBudgetExpenseData() {
    const promiseArr = []
    if (state.deletedBudgetExpenses.length > 0) {
      for (var i = 0; i < state.deletedBudgetExpenses.length; i++) {
        const budgetExpenseId = state.deletedBudgetExpenses[i].budget_expense_id
        let deleteUrl = `${env.camsCoreApiUrl}/budget/${budgetExpenseId}?key=${env.apiKey}`
        promiseArr.push(
          axios.delete(deleteUrl).then((res) => {
            if (res.status === 204) {
              console.log(
                'budget expense id is deleted successfully , id=',
                budgetExpenseId
              )
            }
          })
        )
      }
      Promise.all(promiseArr).then((done) => {
        makeToast({
          type: 'success',
          heading: 'Success',
          message: 'requested budget expenses are deleted successfully',
        })
        dispatch({
          type: SET_FIELD,
          payload: [
            {
              id: 'isBudgetDeleted',
              value: false,
            },
            {
              id: 'deletedBudgetExpenses',
              value: [],
            },
            {
              id: 'deletedBudgetExpense',
              value: {},
            },
          ],
        })
        dispatch({
          type: RESET,
        })
        fetchBudget(state.budgetData.budget_id)
      })
    }
  }

  // finds whether display only access should be given
  const findDisplayOnly = (budgetData) => {
    var displayOnly = false

    if (
      (userProfile.isVendor &&
        budgetData?.status !== BudgetStatus.MORE_INFO_REQUIRED &&
        budgetData?.status !== BudgetStatus.REOPENED &&
        budgetData?.workflow === Workflow.VENDOR) ||
      budgetData?.workflow !== Workflow.VENDOR
    ) {
      displayOnly = true
    }

    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'isDisplayOnlyBudget', value: displayOnly }],
    })
  }

  const schema = Yup.object({
    // lanId: Yup.string().required('Please choose lanId'),
  })

  const formik = useFormik({
    initialValues: {},
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      const validationSchema = schema
      try {
        validateYupSchema(values, validationSchema, true, values)
      } catch (err) {
        return yupToFormErrors(err)
      }
      return {}
    },
    onSubmit: async (values) => {
      const adAuditEntry = addAuditEntryData(
        userId,
        userName,
        state,
        formik.values
      )
      const submitheaders = {
        'content-type': 'application/json',
      }

      const updateBudgetRequest = {
        budget_id: state.budgetData?.budget_id,
        doc_type: state.budgetData?.doc_type,
        year: state.budgetData?.year,
        contract_no: state.budgetData?.contract_no,
        location_id: state.budgetData?.location_id,
        loc_name: state.budgetData?.loc_name,
        loc_address: state.budgetData?.loc_address,
        loc_city: state.budgetData?.loc_city,
        loc_state: state.budgetData?.loc_state,
        contract_name: state.budgetData?.contract_name,
        submitted_by_name: state.budgetData?.submitted_by_name,
        submitted_by_email: state.budgetData?.submitted_by_email,
        total_amount: state.isBudgetChanged
          ? state.grandTotalExpenseAmount
          : state.budgetData?.total_amount,
        total_prs: state.isBudgetChanged
          ? state.totalPrsAmount
          : state.budgetData?.total_prs,
        tgt_annual_amount: state.isBudgetChanged
          ? state.totalAnnualAmount
          : state.budgetData?.tgt_annual_amount,
        tgt_monthly_amount: state.isBudgetChanged
          ? state.totalMonthlyAmount
          : state.budgetData?.tgt_monthly_amount,
        vendor_no: state.budgetData?.vendor_no,
        vendor_name: state.budgetData?.vendor_name,
        workflow:
          values?.workflow !== undefined
            ? values?.workflow
            : state.budgetData?.workflow,
        status:
          values?.status !== undefined
            ? values?.status
            : state.budgetData?.status,
        assigned_user_id:
          values?.lanId !== undefined
            ? values?.lanId
            : state.budgetData?.assigned_user_id,
        assigned_user_name:
          values?.assignedUserName !== undefined
            ? values?.assignedUserName
            : state.budgetData?.assigned_user_name,
        is_reopen:
          values?.isReopen !== undefined
            ? values?.isReopen
            : state.budgetData?.is_reopen,
        reopen_reason:
          values?.reopenReason !== undefined
            ? values?.reopenReason
            : state.budgetData?.reopen_reason,
        create_user_id: state.budgetData?.create_user_id,
        create_ts: state.budgetData?.create_ts,
        update_user_id: userProfile.userId,
        update_ts: state.budgetData?.update_ts,
        budget_expenses: state.isBudgetChanged
          ? state.budgetExpenses
          : state.budgetData?.budget_expenses,
        budget_audit_entries: [adAuditEntry],
        budget_comments: values?.comments?.length !== 0 ? values?.comments : [],
      }

      await axios
        .put(
          `${env.camsCoreApiUrl}/budget?key=${env.apiKey}`,
          updateBudgetRequest,
          {
            headers: submitheaders,
          }
        )
        .then((res) => {
          if (state.isBudgetDeleted) {
            DeleteBudgetExpenseData()
          } else {
            dispatch({
              type: RESET,
            })
            fetchBudget(res.data.budget_id)
          }
          makeToast({
            type: 'success',
            heading: 'Success',
            message: budgetUpdate,
          })
          setBudgetUpdate('')
          // dispatch({
          //   type: SET_FIELD,
          //   payload: [
          //     {
          //       id: 'isBudgetChanged',
          //       value: false,
          //     },
          //   ],
          // })
        })
        .catch((error) => {
          console.log('Error when saving budget creation data', error)
          makeToast({
            type: 'error',
            heading: 'Network Error',
            message: 'Something went wrong. Please try again later',
          })
        })
    },
  })

  useEffect(() => {
    getBudgetAmountDetails(formik)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.budgetExpenses])

  const onReopen = () => {
    setBudgetUpdate(REOPEN)

    formik.setFieldValue('status', BudgetStatus.REOPENED)
    formik.setFieldValue('workflow', Workflow.VENDOR)
    formik.setFieldValue('lanId', userProfile.userId)
    formik.setFieldValue('assignedUserName', userProfile.fullName)

    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'showReopenModal', value: true }],
    })
  }

  const onAssignToTarget = () => {
    setBudgetUpdate(ASSIGNED_TO_TGT)
    findPriorWorkflow(state.budgetData.budget_audit_entries, formik)
    var index = -1
    index = state.budgetData.budget_audit_entries?.findIndex((auditEntry) => {
      return (
        auditEntry.workflow === Workflow.PAA ||
        auditEntry.workflow === Workflow.SITEOPS
      )
    })
    const activityDescription = `${'Workflow is assigned back to '}${
      state.budgetData.budget_audit_entries[index].workflow
    }`
    dispatch({
      type: SET_FIELD,
      payload: [
        { id: 'activityDescr', value: activityDescription },
        { id: 'showUserQueryModal', value: true },
      ],
    })
  }

  const handleSubmit = () => {
    console.log('Inside handle submit')
    setBudgetUpdate(BUDGET_STATUS)
    if (state.isBudgetChanged) {
      const activityDescription = 'Revised Budget expenses are submitted'
      dispatch({
        type: SET_FIELD,
        payload: [{ id: 'activityDescr', value: activityDescription }],
      })

      // if budget is reopened and vendor submits the revised budget
      if (
        state.budgetData?.workflow === Workflow.VENDOR &&
        state.budgetData?.status === BudgetStatus.REOPENED
      ) {
        formik.setFieldValue('status', BudgetStatus.NOT_STARTED)
        formik.setFieldValue('workflow', Workflow.PAA)
        findRecentAnalyst(state.budgetData.budget_audit_entries, formik)
      }

      if (
        state.budgetData?.workflow === Workflow.VENDOR &&
        state.budgetData?.status === BudgetStatus.MORE_INFO_REQUIRED
      ) {
        findPriorWorkflow(state.budgetData.budget_audit_entries, formik)
      }
    }
    formik.handleSubmit()
  }

  const prepareToUploadAttachments = async () => {
    setBudgetUpdate(ATTACHMENTS)
    const activityDescription = 'Uploaded Attachment'
    formik.setFieldValue('status', state.budgetData.status)
    formik.setFieldValue('workflow', state.budgetData.workflow)
    formik.setFieldValue('lanId', state.budgetData?.assigned_user_id)
    formik.setFieldValue(
      'assignedUserName',
      state.budgetData?.assigned_user_name
    )
    dispatch({
      type: SET_FIELD,
      payload: [
        { id: 'activityDescr', value: activityDescription },
        { id: 'viewAttachmentStatus', value: true },
      ],
    })

    uploadAttachments(
      props.id,
      state.budgetData?.vendor_no,
      state.budgetData?.doc_type
    )
  }

  return (
    <LayoutBody includeRail className="hc-pa-md">
      <Grid.Container>
        <Form>
          <Grid.Container>
            <BreadCrumb budgetId={props.id} />
            <Grid.Item xs={12}>
              {userProfile.isVendor &&
                state.budgetData?.status === BudgetStatus.APPROVED && (
                  <Button type="primary" css={editButtons} onClick={onReopen}>
                    Set to Reopen
                  </Button>
                )}
              {userProfile.isVendor &&
                state.budgetData?.workflow === Workflow.VENDOR &&
                state.budgetData?.status ===
                  BudgetStatus.MORE_INFO_REQUIRED && (
                  <>
                    <Button
                      type="primary"
                      css={editButtons}
                      onClick={onAssignToTarget}
                    >
                      No Corrections! Assign back to Target
                    </Button>
                  </>
                )}
            </Grid.Item>
          </Grid.Container>
          <Grid.Container justify="space-between" className="hc-pa-none">
            <Grid.Item md={7} xs={12}>
              <ViewVendorDetails vendorDetails={state.budgetData} />
              <ViewLocationDetails locationDetails={state.budgetData} />
              <ViewContractDetails contractDetails={state.budgetData} />

              {state.showWorkflow && (
                <ViewWorkflowModal
                  headingText="Assign the workflow?"
                  formik={formik}
                  saveWorkflow={formik.handleSubmit}
                />
              )}
            </Grid.Item>

            <Grid.Item md={5} xs={12} className="hc-pl-3x">
              <ViewYearAndDocType genericDetails={state.budgetData} />
              <ViewWorkflowDetails
                workflowDetails={state.budgetData}
                attachmentAvailable={state.savedAttachments?.length !== 0}
              />
              <ViewDates dates={state.budgetData} />
              <ViewReopenDetails reopenDetails={state.budgetData} />
            </Grid.Item>
          </Grid.Container>
          {state.budgetDataLoaded ? (
            <Grid.Item xs={12}>
              <BudgetExpenses expenseheadingText="Budget Expenses" />
              <br />
              <BudgetAmountDetails formik={formik} />
              <Grid.Container
                direction="row-reverse"
                className="hc-pt-expanded"
              >
                <Grid.Item hidden={state.isSubmit}>
                  <Button
                    onClick={() => {
                      dispatch({
                        type: SET_FIELD,
                        payload: [{ id: 'isCancel', value: true }],
                      })
                    }}
                    disabled={state.budgetCreatedFlag || !state.isBudgetChanged}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    type="primary"
                    disabled={!state.isBudgetChanged}
                    onClick={handleSubmit}
                  >
                    Submit
                  </Button>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          ) : (
            <LoadingSpinner />
          )}
          <Grid.Item md={7} xs={12}>
            <DownloadAttachments />
          </Grid.Item>
          {state.budgetData?.status !== BudgetStatus.APPROVED && (
            <>
              <Attachments />
              <div className="hc-pa-normal hc-pb-dense hc-mt-lg">
                <Grid.Container direction="row-reverse">
                  <Grid.Item md={7} xs={12}>
                    <Button
                      size="dense"
                      type="primary"
                      onClick={prepareToUploadAttachments}
                      disabled={uploadedAttachments?.length === 0}
                    >
                      Save Attachments
                    </Button>
                  </Grid.Item>
                </Grid.Container>
              </div>
            </>
          )}
        </Form>

        {state.viewAttachmentStatus && (
          <ViewSaveTimelineModal
            saveAttachmentAuditEntry={formik.handleSubmit}
            redirectUrl={`/budget/${props.id}`}
            requestId={props.id}
            vendorNo={state.budgetData?.vendor_no}
            docType={state.budgetData?.doc_type}
          />
        )}
      </Grid.Container>
      <Grid.Container className="hc-pa-none hc-mt-2x">
        <Grid.Item md={7} xs={12}>
          {state.budgetDataLoaded && (
            <div id="showActivity" ref={showActivity}>
              <Grid.Item className="hc-bg-white">
                <NotesTab
                  formik={formik}
                  budgetDetails={state.budgetData}
                  userProfile={userProfile}
                  saveComment={formik.handleSubmit}
                  setBudgetStatus={setBudgetUpdate}
                />
              </Grid.Item>
            </div>
          )}
        </Grid.Item>
      </Grid.Container>
      <ViewUserQueryModal
        headingText="Query/Clarification"
        placeholderText="Enter your clarification here..."
        formik={formik}
        userProfile={userProfile}
        saveUserQuery={formik.handleSubmit}
      />
      <ViewReopenModal
        headingText="Reopen Budget"
        placeholderText="Enter Reopen Reason here..."
        formik={formik}
        saveReopenReason={formik.handleSubmit}
      />

      <CancelModal
        headingText="Cancel Budget Creation!"
        heading="Do you want to cancel the Budget Creation?"
      />
    </LayoutBody>
  )
}
