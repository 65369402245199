import React from 'react'
import { useParams } from 'react-router'
import { BPBudgetView } from './BPBudgetView'
import { TMBudgetView } from './TMBudgetView'
import { useProfile } from '../../../globalComponents/UserProfileProvider'
import AttachmentContextProvider from '../../Context/AttachmentContext'

export const BudgetView = (props) => {
  const { userProfile } = useProfile()
  const params = useParams()

  return (
    <React.Fragment>
      {userProfile?.isVendor ? (
        <AttachmentContextProvider>
          <BPBudgetView id={params.id} />
        </AttachmentContextProvider>
      ) : (
        <AttachmentContextProvider>
          <TMBudgetView id={params.id} />
        </AttachmentContextProvider>
      )}
    </React.Fragment>
  )
}
