import { Button, Grid, Form, Card, Modal } from '@enterprise-ui/canvas-ui-react'
import { useGlobalForm } from '../../Context/GlobalFormStateContext'
import { SET_FIELD } from '../../../globalConfig/common/ConstantData'

export const ViewReopenModal = ({
  headingText,
  placeholderText,
  formik,
  saveReopenReason,
}) => {
  const [state, dispatch] = useGlobalForm()

  const clearValues = () => {
    formik.setFieldValue('reopenReason', '')
    formik.setFieldValue('isReopen', false)
  }

  const handleClose = () => {
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'showReopenModal', value: false }],
    })
  }

  function saveActivity() {
    const activityDescription = `${'Budget is reopened'}`
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'activityDescr', value: activityDescription }],
    })

    saveReopenReason()
  }

  return (
    <div>
      <Modal
        headingText={headingText}
        className="myModal"
        size="dense"
        isVisible={state.showReopenModal}
        onRefuse={() => {
          clearValues()
          handleClose()
        }}
      >
        <Grid.Container className="hc-pt-normal hc-pl-dense">
          <Grid.Container className="hc-pa-normal">
            <Grid.Item xs={12}>
              <Card className="hc-pa-normal">
                <Grid.Item xs={12}>
                  <Form.Field
                    id="reopenReason"
                    type="textarea"
                    placeholder={placeholderText}
                    maxLength="500"
                    value={formik.values.reopenReason}
                    onChange={(event) => {
                      formik.setFieldValue('isReopen', true)
                      formik.setFieldValue('reopenReason', event.target.value)
                    }}
                    autoFocus
                  />
                </Grid.Item>
                <Grid.Container direction="row-reverse">
                  <Grid.Item>
                    <Button
                      className="hc-ma-none"
                      type="primary"
                      size="dense"
                      onClick={() => {
                        clearValues()
                        handleClose()
                      }}
                    >
                      CANCEL
                    </Button>
                  </Grid.Item>
                  <Grid.Item>
                    <Button
                      className="hc-ma-none"
                      type="primary"
                      size="dense"
                      disabled={formik.values.reopenReason === ''}
                      onClick={() => {
                        saveActivity()
                        handleClose()
                      }}
                    >
                      OK
                    </Button>
                  </Grid.Item>
                </Grid.Container>
              </Card>
            </Grid.Item>
          </Grid.Container>
        </Grid.Container>
      </Modal>
    </div>
  )
}
