import axios from 'axios'
import { Workflow, SET_FIELD } from '../../globalConfig/common/ConstantData'
import { useEnv } from '@praxis/component-runtime-env'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import {
  currencyFormatter,
  amountFormatter,
  amountFormatters,
} from './NumericalFormatter'
import _ from 'lodash'

export function GenericFunctions() {
  const env = useEnv()
  const [state, dispatch] = useGlobalForm()

  function addAuditEntryData(userId, userName, state, formikValues) {
    const adAuditEntry = {
      activity_descr: `${state.activityDescr} ${' '}`,
      user_id: userId,
      user_name: userName,
      workflow: formikValues?.workflow,
      status: formikValues?.status,
      assigned_user_id: formikValues?.lanId,
      assigned_user_name: formikValues?.assignedUserName,
      activity_ts: new Date().toISOString(),
    }
    return adAuditEntry
  }

  function findRecentAnalyst(auditEntries, formik) {
    let sortedAuditEntries = _.orderBy(
      auditEntries,
      ['budget_audit_id'],
      ['desc']
    )

    var index = sortedAuditEntries.findIndex((auditEntry) => {
      return auditEntry.workflow === Workflow.PAA
    })

    formik.setFieldValue('lanId', sortedAuditEntries[index].assigned_user_id)
    formik.setFieldValue(
      'assignedUserName',
      sortedAuditEntries[index].assigned_user_name
    )
  }

  function findPriorWorkflow(auditEntries, formik) {
    var index = -1
    index = auditEntries?.findIndex((auditEntry) => {
      return (
        auditEntry.workflow === Workflow.PAA ||
        auditEntry.workflow === Workflow.SITEOPS
      )
    })
    formik.setFieldValue('lanId', auditEntries[index].assigned_user_id)
    formik.setFieldValue(
      'assignedUserName',
      auditEntries[index].assigned_user_name
    )
    formik.setFieldValue('status', auditEntries[index].status)
    formik.setFieldValue('workflow', auditEntries[index].workflow)
  }

  function getExpenseTypes() {
    axios
      .get(`${env.camsCoreApiUrl}/expense_pool?key=${env.apiKey}`)
      .then((res) => {
        const expenseTypeOptions = res.data.map((expensePool) => ({
          id: expensePool.expense_type_descr,
          value: expensePool.expense_type_descr,
          label: expensePool.expense_type_descr,
        }))

        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'expenseTypeOptions', value: expenseTypeOptions },
            { id: 'expensePool', value: res.data },
          ],
        })
      })
      .catch((error) => {
        console.log('Error when fetching expense pool data', error)
      })
  }

  async function getVendorsByBP(businessPartnerId) {
    await axios
      .get(
        `${env.camsCoreApiUrl}/business_partner/${businessPartnerId}?key=${env.apiKey}`
      )
      .then((res) => {
        const vendorsData = res.data.map((vendorData) => {
          return {
            value: vendorData.gmsVendorNumber,
            label: `${vendorData.gmsVendorNumber}${' ('}${
              vendorData.vendorName
            }${')'}`,
          }
        })

        const vendorDetails = res.data.map((vendorInfo) => {
          return {
            vendorNo: vendorInfo.gmsVendorNumber,
            vendorName: vendorInfo.vendorName,
          }
        })

        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'vendorDataOptions', value: vendorsData },
            { id: 'vendorDetails', value: vendorDetails },
          ],
        })
      })
      .catch((error) => {
        console.log(
          'Error when fetching vendor details by business partner id',
          error
        )
      })
  }

  function getBudgetAmountDetails(formik) {
    var grandTotalExpenseAmount = _.reduce(
      state.budgetExpenses,
      function (sum, n) {
        if (n?.total_expense_amount !== 0) {
          return sum + n?.total_expense_amount
        } else {
          return sum + 0.0
        }
      },
      0.0
    )

    var totalPrsAmount = _.reduce(
      state.budgetExpenses,
      function (sum, n) {
        if (n?.prs_amount !== 0) {
          return sum + n?.prs_amount
        } else {
          return sum + 0.0
        }
      },
      0.0
    )

    var totalAdminAmount = _.reduce(
      state.budgetExpenses,
      function (sum, n) {
        if (n?.admin_amount !== 0) {
          return sum + n?.admin_amount
        } else {
          return sum + 0.0
        }
      },
      0.0
    )

    var totalAnnualAmount = _.reduce(
      state.budgetExpenses,
      function (sum, n) {
        if (n?.annual_amount !== 0) {
          return sum + n?.annual_amount
        } else {
          return sum + 0.0
        }
      },
      0.0
    )

    var totalMonthlyAmount = _.reduce(
      state.budgetExpenses,
      function (sum, n) {
        if (n?.monthly_amount !== 0) {
          return sum + n?.monthly_amount
        } else {
          return sum + 0.0
        }
      },
      0.0
    )

    if (formik !== undefined) {
      formik.setFieldValue(
        'totalAmount',
        amountFormatter(parseFloat(grandTotalExpenseAmount))
      )

      formik.setFieldValue('totalPrs', currencyFormatter(totalPrsAmount))

      formik.setFieldValue(
        'totalAdminAmount',
        amountFormatter(parseFloat(totalAdminAmount))
      )
      formik.setFieldValue(
        'totalMonthlyAmount',
        amountFormatter(parseFloat(totalMonthlyAmount))
      )

      formik.setFieldValue(
        'totalAnnualAmount',
        amountFormatter(parseFloat(totalAnnualAmount))
      )
    }

    dispatch({
      type: SET_FIELD,
      payload: [
        { id: 'grandTotalExpenseAmount', value: grandTotalExpenseAmount },
        { id: 'totalPrsAmount', value: totalPrsAmount },
        { id: 'totalAnnualAmount', value: totalAnnualAmount },
        { id: 'totalAdminAmount', value: totalAdminAmount },
        { id: 'totalMonthlyAmount', value: totalMonthlyAmount },
      ],
    })
  }

  function getActualAmountDetails(formik) {
    var grandTotalExpenseAmount = _.reduce(
      state.actualExpenses,
      function (sum, n) {
        if (n?.total_expense_amount !== 0) {
          return sum + n?.total_expense_amount
        } else {
          return sum + 0.0
        }
      },
      0.0
    )

    var totalPrsAmount = _.reduce(
      state.actualExpenses,
      function (sum, n) {
        if (n?.prs_amount !== 0) {
          return sum + n?.prs_amount
        } else {
          return sum + 0.0
        }
      },
      0.0
    )

    var totalAdminAmount = _.reduce(
      state.actualExpenses,
      function (sum, n) {
        if (n?.admin_amount !== 0) {
          return sum + n?.admin_amount
        } else {
          return sum + 0.0
        }
      },
      0.0
    )

    var totalAnnualAmount = _.reduce(
      state.actualExpenses,
      function (sum, n) {
        if (n?.annual_amount !== 0) {
          return sum + n?.annual_amount
        } else {
          return sum + 0.0
        }
      },
      0.0
    )

    if (formik !== undefined) {
      formik.setFieldValue(
        'totalAmount',
        amountFormatter(parseFloat(grandTotalExpenseAmount))
      )

      formik.setFieldValue(
        'totalPrs',
        amountFormatter(parseFloat(totalPrsAmount))
      )

      formik.setFieldValue(
        'totalAdminAmount',
        amountFormatter(parseFloat(totalAdminAmount))
      )

      formik.setFieldValue(
        'totalAnnualAmount',
        amountFormatter(parseFloat(totalAnnualAmount))
      )
    }

    dispatch({
      type: SET_FIELD,
      payload: [
        { id: 'grandTotalExpenseAmount', value: grandTotalExpenseAmount },
        { id: 'totalPrsAmount', value: totalPrsAmount },
        { id: 'totalAnnualAmount', value: totalAnnualAmount },
        { id: 'totalAdminAmount', value: totalAdminAmount },
      ],
    })
  }

  function isActualSummaryChanged() {
    dispatch({
      type: SET_FIELD,
      payload: [
        {
          id: 'isActualChanged',
          value: true,
        },
      ],
    })
  }

  function deriveFinalActualAmount(formik) {
    // Helper function to safely parse a number
    const safeParse = (value) => {
      const parsed = parseFloat(value)
      return isNaN(parsed) ? 0 : parsed
    }

    // Helper function to format amounts
    const formatAmount = (value) => amountFormatters(safeParse(value))

    // Initialize variables
    let taxAmount = 0
    let totalDueAmount = 0
    let finalDueAmount = 0

    // Format formik values safely
    formik.setFieldValue('taxRate', formatAmount(formik.values.taxRate))
    formik.setFieldValue(
      'priorPaymentAmount',
      formatAmount(formik.values.priorPaymentAmount)
    )
    formik.setFieldValue(
      'priorTaxPaymentAmount',
      formatAmount(formik.values.priorTaxPaymentAmount)
    )

    // Calculate taxAmount
    const totalAnnualAmount = safeParse(state.totalAnnualAmount)
    const taxRate = safeParse(formik.values.taxRate)
    if (totalAnnualAmount && taxRate) {
      taxAmount = formatAmount(totalAnnualAmount * (taxRate / 100))
    }

    // Calculate totalDueAmount
    totalDueAmount = formatAmount(totalAnnualAmount + safeParse(taxAmount))

    // Calculate prior payments safely
    const priorPaymentAmount = safeParse(formik.values.priorPaymentAmount)
    const priorTaxPaymentAmount = safeParse(formik.values.priorTaxPaymentAmount)

    // Calculate finalDueAmount
    finalDueAmount = formatAmount(
      totalDueAmount - (priorPaymentAmount + priorTaxPaymentAmount)
    )

    // Set final calculated values
    formik.setFieldValue('taxAmount', taxAmount)
    formik.setFieldValue('totalDueAmount', totalDueAmount)
    formik.setFieldValue('finalDueAmount', finalDueAmount)
  }

  return {
    addAuditEntryData,
    findRecentAnalyst,
    findPriorWorkflow,
    getExpenseTypes,
    getBudgetAmountDetails,
    getActualAmountDetails,
    getVendorsByBP,
    deriveFinalActualAmount,
    isActualSummaryChanged,
  }
}
