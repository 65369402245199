import { Grid, Heading, Input } from '@enterprise-ui/canvas-ui-react'
import '../Budget/Custom.css'

export const BudgetAmountDetails = ({ formik }) => {
  const locationHeading = 'Budget Details'

  return (
    <Grid.Container className="hc-pt-expanded hc-ml-min">
      <Heading size={6}>{locationHeading} </Heading>
      <Grid.Container className="hc-pt-normal hc-mr-normal">
        <Grid.Item xs={10} md={3}>
          <Input.Label htmlFor="total_amount_lbl">
            Total Submitted Expense
          </Input.Label>
          <Input.Currency
            id="totalAmount"
            display={formik?.values.currencyType}
            value={formik?.values.totalAmount}
            allowDecimals
            disabled
          />
        </Grid.Item>

        <Grid.Item xs={10} md={3}>
          <Input.Label htmlFor="tax_amount_lbl">
            Target Share - Annual
          </Input.Label>
          <Input.Currency
            id="totalAnnualAmount"
            display={formik?.values.currencyType}
            value={formik?.values.totalAnnualAmount}
            allowDecimals
            disabled
          />
        </Grid.Item>

        <Grid.Item xs={10} md={3}>
          <Input.Label htmlFor="monthly_amount_lbl">
            Target Share - Monthly
          </Input.Label>
          <Input.Currency
            id="monthlyAmount"
            display={formik?.values.currencyType}
            value={formik?.values.totalMonthlyAmount}
            allowDecimals
            disabled
          />
        </Grid.Item>
      </Grid.Container>
    </Grid.Container>
  )
}

export default BudgetAmountDetails
