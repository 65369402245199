import { Button, Form, Grid, Input } from '@enterprise-ui/canvas-ui-react'

export const EmailNotification = (props) => {
  return (
    <Grid.Item className="hc-mb-lg">
      <Input noValidate onSubmit={() => {}}>
        <Grid.Container>
          <Grid.Item xs={6}>
            <Form.Field label=" Subject" required type="text" />
          </Grid.Item>
        </Grid.Container>
        <Grid.Container>
          <Grid.Container>
            <Grid.Item>
              <Input.Label required>&nbsp;&nbsp;&nbsp;&nbsp; Body:</Input.Label>
            </Grid.Item>
          </Grid.Container>
          <Grid.Item xs={6}>
            <Input.Textarea id="demo_00" onChange={(event) => {}} />
          </Grid.Item>
          <Grid.Item xs={6}>
            <Button type="primary">Send Email</Button>
          </Grid.Item>
        </Grid.Container>
      </Input>
    </Grid.Item>
  )
}
