import { Layout, Grid } from '@enterprise-ui/canvas-ui-react'
import { SearchProvider } from '../SearchContext/SearchProvider'
import { BudgetSearch } from './BudgetSearch'
import { BudgetSideFilter } from './BudgetSideFilter'

const Budget = (props) => {
  return (
    <SearchProvider type="budget">
      <Layout.Body>
        <Grid.Container padding="dense" align="center">
          <Grid.Item xs={12}>
            <BudgetSearch />
          </Grid.Item>
        </Grid.Container>
      </Layout.Body>
      <Layout.Rail>
        <BudgetSideFilter />
      </Layout.Rail>
    </SearchProvider>
  )
}

export default Budget
