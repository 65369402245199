import { Grid, Modal, Button, Form } from '@enterprise-ui/canvas-ui-react'
import { useEffect, useState } from 'react'
import {
  SET_FIELD,
  Workflow,
  BudgetStatus,
} from '../../../globalConfig/common/ConstantData'
import { useEnv } from '@praxis/component-runtime-env'
import { useGlobalForm } from '../../Context/GlobalFormStateContext'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useCommonRequest } from '../../../globalComponents/hooks/useCommonRequest'

export const ViewWorkflowModal = ({ headingText, formik, saveWorkflow }) => {
  const env = useEnv()
  const [state, dispatch] = useGlobalForm()
  const [workflowOptions, setWorkflowOptions] = useState([])
  const [assigneeOptions, setAssigneeOptions] = useState([])
  const [analystList, setAnalystList] = useState([])
  const [managerList, setManagerList] = useState([])
  const [siteOpsList, setSiteOpsList] = useState([])
  const { getAssigneeForAdGroup } = useCommonRequest()

  useEffect(() => {
    getAllAssigneeInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const workflowOptions = state.budgetWorkflow?.map((workflow) => ({
      id: workflow,
      value: workflow,
      label: workflow,
    }))
    setWorkflowOptions(workflowOptions)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.budgetWorkflow])

  async function getAllAssigneeInfo() {
    await getAssigneeForAdGroup(env.auth.analystRole[0])
      .then((data) => {
        setAnalystList(data)
      })
      .catch((error) => {
        console.log('Error fetching ldap member details', error)
        setAnalystList([])
      })

    await getAssigneeForAdGroup(env.auth.managerRole[0])
      .then((data) => {
        setManagerList(data)
      })
      .catch((error) => {
        console.log('Error fetching ldap member details', error)
        setManagerList([])
      })

    await getAssigneeForAdGroup(env.auth.siteOpsRole[0])
      .then((data) => {
        setSiteOpsList(data)
      })
      .catch((error) => {
        console.log('Error fetching ldap member details', error)
        setSiteOpsList([])
      })
  }

  const handleClose = () => {
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'showWorkflow', value: false }],
    })
  }

  const clearValues = () => {
    formik.setFieldValue('lanId', '')
    formik.setFieldValue('assignedUserName', '')
    formik.setFieldValue('workflow', '')
  }

  const fetchAssignees = () => {
    setAssigneeOptions([])
    formik.setFieldValue('lanId', undefined)
    formik.setFieldValue('assignedUserName', undefined)
    if (formik.values?.workflow === Workflow.PAA) {
      setAssigneeOptions(analystList)
    } else if (formik.values?.workflow === Workflow.PAM) {
      setAssigneeOptions(managerList)
    } else if (formik.values?.workflow === Workflow.SITEOPS) {
      setAssigneeOptions(siteOpsList)
    }
  }

  const onWorkflowAssignment = () => {
    formik.validateForm().then((res) => {
      formik.setErrors({})
      formik.setFieldValue('status', BudgetStatus.SUBMITTED_FOR_APPROVAL)
      const activityDescription = `${' '}${BudgetStatus.SUBMITTED_FOR_APPROVAL}`

      dispatch({
        type: SET_FIELD,
        payload: [{ id: 'activityDescr', value: activityDescription }],
      })

      saveWorkflow()
      handleClose()
    })
  }

  return (
    <div>
      <Modal
        headingText={headingText}
        className="myModal"
        size="dense"
        isVisible={state.showWorkflow}
        onRefuse={() => {
          clearValues()
          handleClose()
        }}
      >
        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs={6}>
              <Form.Field
                id="workflow"
                label="Workflow"
                type="select"
                className="hc-ta-left"
                value={formik?.values?.workflow}
                onUpdate={formik.setFieldValue}
                options={workflowOptions}
                onBlur={(e) => {
                  fetchAssignees(e)
                }}
              />
            </Grid.Item>
            <Grid.Item xs={6} className="hc-pb-none">
              <Autocomplete
                id="lanId"
                options={assigneeOptions}
                label="Assignee"
                onUpdate={(id, value) => {
                  formik.setFieldValue(id, value?.id)
                  formik.setFieldValue('assignedUserName', value?.label)
                }}
                error={
                  formik.touched?.lanId && formik.errors?.lanId !== undefined
                }
                errorText={formik.errors?.lanId}
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container direction="row-reverse" spacing="dense">
            <Grid.Item>
              <Button type="primary" onClick={onWorkflowAssignment}>
                Yes
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button
                type="secondary"
                onClick={() => {
                  clearValues()
                  handleClose()
                }}
              >
                No
              </Button>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Modal>
    </div>
  )
}
