import { Layout, Grid } from '@enterprise-ui/canvas-ui-react'
import { SearchProvider } from '../SearchContext/SearchProvider'
import { ActualSearch } from './ActualSearch'
import { ActualSideFilter } from './ActualSideFilter'

const Actual = (props) => {
  return (
    <SearchProvider type="actual">
      <Layout.Body>
        <Grid.Container padding="dense" align="center">
          <Grid.Item xs={12}>
            <ActualSearch />
          </Grid.Item>
        </Grid.Container>
      </Layout.Body>
      <Layout.Rail>
        <ActualSideFilter />
      </Layout.Rail>
    </SearchProvider>
  )
}

export default Actual
