import { Button } from '@enterprise-ui/canvas-ui-react'
import { SET_FIELD } from '../../../globalConfig/common/ConstantData'
import { useGlobalForm } from '../../Context/GlobalFormStateContext'
import axios from 'axios'
import { useEnv } from '@praxis/component-runtime-env'
import { useAuth } from '@praxis/component-auth'
import { EnterpriseIcon, FileIcon } from '@enterprise-ui/icons'

const ViewAttachment = (props) => {
  const auth = useAuth()
  const env = useEnv()
  const [state, dispatch] = useGlobalForm()

  const viewAttachments = async (attachmentId, filename) => {
    return await axios
      .get(`${env.camsCoreApiUrl}/attachment/${attachmentId}`, {
        responseType: 'blob',
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-api-key': `${env?.apiKey}`,
          Authorization: `Bearer ${auth?.session?.accessToken}`,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'setAttachmentView', value: true }],
          })
          let blob
          let fileType = filename.slice(
            filename.lastIndexOf('.') + 1,
            filename.length
          )
          if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
            blob = new Blob([res.data], { type: 'image/png' })
            dispatch({
              type: SET_FIELD,
              payload: [
                { id: 'isAttachmentNotViewable', value: false },
                { id: 'mediaType', value: '' },
                { id: 'fileName', value: filename },
                { id: 'fileUrl', value: window.URL.createObjectURL(blob) },
                {
                  id: 'format',
                  value: ['pdf', 'jpg', 'jpeg', 'png'].includes(fileType),
                },
              ],
            })
          } else if (fileType === 'pdf') {
            blob = new Blob([res.data], { type: 'application/pdf' })
            dispatch({
              type: SET_FIELD,
              payload: [
                { id: 'isAttachmentNotViewable', value: false },
                { id: 'mediaType', value: 'PDF' },
                { id: 'fileName', value: filename },
                { id: 'fileUrl', value: window.URL.createObjectURL(blob) },
                {
                  id: 'format',
                  value: ['pdf', 'jpg', 'jpeg', 'png'].includes(fileType),
                },
              ],
            })
          } else {
            dispatch({
              type: SET_FIELD,
              payload: [
                { id: 'isAttachmentNotViewable', value: true },
                { id: 'mediaType', value: '' },
                { id: 'fileName', value: '' },
                { id: 'fileUrl', value: '' },
                {
                  id: 'format',
                  value: false,
                },
              ],
            })
          }
        }
      })
      .catch((reason) => {
        dispatch({
          type: SET_FIELD,
          payload: [
            { id: 'viewAttachment', value: undefined },
            { id: 'attachmentsError', value: true },
            { id: 'toastHeading', value: 'Error Viewing the File' },
            {
              id: 'toastMessage',
              value: 'There was an error viewing the file. Please try again.',
            },
          ],
        })
      })
  }

  const handleAttachmentView = async () => {
    await dispatch({
      type: SET_FIELD,
      payload: [{ id: 'viewAttachment', value: props.rowIndex }],
    })
    let allFiles = state.savedAttachments ? state.savedAttachments : []
    let attachment = allFiles[props.rowIndex]
    await viewAttachments(
      attachment['attachmentId'],
      attachment['fileName']
    ).then(async () => {
      await dispatch({
        type: SET_FIELD,
        payload: [
          { id: 'viewAttachment', value: undefined },
          { id: 'fileRowIndex', value: props.rowIndex },
        ],
      })
    })
  }

  return (
    <Button
      xs={1}
      iconOnly
      aria-label="View File Icon"
      type="ghost"
      onClick={() => {
        handleAttachmentView()
      }}
      isLoading={state.viewAttachment === props.rowIndex}
    >
      <EnterpriseIcon icon={FileIcon} size="inline" />
    </Button>
  )
}

export default ViewAttachment
