import React from 'react'
import { useAuth } from '@praxis/component-auth'
import { useEnv } from '@praxis/component-runtime-env'
import UserProfileContext from './UserProfileContext'
import { useState, useEffect } from 'react'
import LogIn from '../views/LogIn'
import { getDomainName } from '../views/AuthUtils'
import { useVendorApi } from './hooks/useVendorApi'

export const ANALYST = 'ANALYST'
export const MANAGER = 'MANAGER'
export const SITEOPS = 'SITEOPS'
export const ADMIN = 'ADMIN'
export const VENDOR = 'VENDOR'

export const UserProfileProvider = (props) => {
  const { session, isAuthorized, isAuthenticated, login } = useAuth()
  const [vendorData, setVendorData] = useState([])
  const { auth } = useEnv()
  const { getVendorList } = useVendorApi()

  function userProfile() {
    const userProfile = {
      userId: session.userInfo.lanId,
      firstName: session.userInfo.firstName,
      lastName: session.userInfo.lastName,
      fullName: session.userInfo.fullName,
      emailId: session.userInfo.email,
      isAdmin: isAdminRole(),
      isVendor: isVendor(),
      isManager: isManager(),
      isSiteOps: isSiteOps(),
      isAnalyst: isAnalyst(),
      vendorList: vendorData,
    }

    return userProfile
  }

  useEffect(() => {
    getVendorInfo()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function getVendorInfo() {
    if (getUserRole() === VENDOR) {
      await getVendorList(session.userInfo.lanId)
        // getVendorList('BAC83513') // - For testing only
        .then((data) => {
          setVendorData(data)
        })
        .catch((error) => {
          console.log('Error fetching Vendors', error)
          setVendorData([])
        })
    }
  }

  function isAdminRole() {
    if (getUserRole() === ADMIN) {
      return true
    }
    return false
  }

  function isVendor() {
    if (getUserRole() === VENDOR) {
      return true
    }
    return false
  }

  function isManager() {
    if (getUserRole() === MANAGER) {
      return true
    }
    return false
  }

  function isAnalyst() {
    if (getUserRole() === ANALYST) {
      return true
    }
    return false
  }

  function isSiteOps() {
    if (getUserRole() === SITEOPS) {
      return true
    }
    return false
  }

  function getUserRole() {
    // return VENDOR

    if (isAuthorized(auth.adminRole)) {
      return ADMIN
    }

    if (isAuthorized(auth.managerRole)) {
      return MANAGER
    }

    if (isAuthorized(auth.analystRole)) {
      return ANALYST
    }

    if (isAuthorized(auth.siteOpsRole)) {
      return SITEOPS
    }

    if (isAuthorized(auth.vendorRole)) {
      return VENDOR
    }

    console.log(session?.userInfo?.memberOf)
    return undefined
  }

  if (!isAuthenticated()) {
    if (getDomainName() === 'partnersonline.com') {
      //If POL, don't display login screen and land user directly to home page
      login({ redirect: window.location.href })
    } else {
      return <LogIn />
    }
  }

  // if (getUserRole() === undefined) {
  //   return (
  //     <LayoutBody>
  //       <h1>Unauthorized :</h1>
  //       <h4>Please get the correct access to use this application</h4>
  //     </LayoutBody>
  //   )
  // }

  return (
    <UserProfileContext.Provider
      value={{
        userProfile: userProfile(),
        userRole: getUserRole(),
        hasAnyAdminRole: isAdminRole(),
        vendor: isVendor(),
      }}
    >
      {props.children}
    </UserProfileContext.Provider>
  )
}

export function useProfile() {
  const context = React.useContext(UserProfileContext)
  if (context === undefined) {
    throw new Error(`useProfile must be used within a Use Profile Provider`)
  }
  return context
}
