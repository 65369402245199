import { Grid, Heading, Form } from '@enterprise-ui/canvas-ui-react'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { SET_FIELD } from '../../globalConfig/common/ConstantData'
import '../Budget/Custom.css'
/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

const searchPosRel = css`
  position: relative;
`

export const FiscalYear = ({ formik }) => {
  const [state, dispatch] = useGlobalForm()
  const formData = formik?.values

  function clearValues() {
    formik.setErrors({})
    dispatch({
      type: SET_FIELD,
      payload: [
        { id: 'isLocationShowable', value: false },
        { id: 'isContractShowable', value: false },
        { id: 'isLocAttributesShowable', value: false },
        { id: 'locationOptions', values: [] },
      ],
    })
  }

  const generalHeading = 'Choose the Fiscal Year'

  return (
    <Grid.Container className="hc-pt-expanded hc-ml-min">
      <Heading size={6}>{generalHeading} </Heading>
      <Grid.Container className="hc-pt-normal">
        <Grid.Item xs={10} md={3} css={searchPosRel}>
          <Form.Field
            id="fiscalYear"
            label="Fiscal Year"
            type="select"
            className="hc-ta-left"
            onUpdate={formik.setFieldValue}
            options={state.yearOptions}
            value={formData.fiscalYear || ''}
            error={formik.errors.fiscalYear !== undefined}
            errorText={formik.errors.fiscalYear}
            required
            disabled={state.isLocationShowable}
            onBlur={(e) => {
              e.preventDefault()
              clearValues(e)
            }}
          />
        </Grid.Item>
        <Grid.Item xs={10} md={3}>
          <Form.Field
            id="documentType"
            label="Type of Document Submitting"
            className="hc-ta-left"
            value={formData.documentType}
            disabled={true}
          />
        </Grid.Item>
      </Grid.Container>
    </Grid.Container>
  )
}

export default FiscalYear
