import { Card, Grid, Heading } from '@enterprise-ui/canvas-ui-react'
import { BudgetLandingCards } from '../../globalConfig/common/ConstantData'
import { useState, useEffect } from 'react'
import '../Budget/Custom.css'

export const StatusCountLandingCard = ({ statusCount }) => {
  const [aggregateStatus, setAggregateStatus] = useState([])
  useEffect(() => {
    if (statusCount !== undefined) {
      const updatedCards = BudgetLandingCards.map((card) => ({
        ...card,
        count: (
          statusCount.find((apiItem) => apiItem.status === card.status) ?? {
            count: 0,
          }
        ).count,
      }))
      setAggregateStatus(updatedCards)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusCount])
  return (
    <Grid.Container className="hc-pt-md">
      {aggregateStatus?.map(({ status, count }, index) => (
        <Grid.Item lg={3} md={6} xs={12} key={status}>
          <Card
            className="status-card hc-pt-sm hc-pl-md hc-pr-none"
            style={{
              maxHeight: '70px',
              position: 'relative',
              backgroundColor: index % 2 === 0 ? '#a0e7e5' : '#ffd166', // Alternating background colors
            }}
            elevation={2}
          >
            <Heading size={6} className="status-title">
              {status}
            </Heading>
            <Grid.Container className="hc-pt-none hc-pl-none hc-pb-expanded">
              <Grid.Item>
                <span className="hc-fs-md">
                  <strong>{count}</strong>
                </span>
              </Grid.Item>
            </Grid.Container>
          </Card>
        </Grid.Item>
      ))}
    </Grid.Container>
  )
}
