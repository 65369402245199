import { BottomBorderDiv } from '../CamsDivComponent'
import Section, { SectionItem } from '../Section'

export const ViewLocationDetails = ({ locationDetails }) => {
  return (
    <BottomBorderDiv>
      <Section
        labelGridSize={3}
        detail={false}
        heading={'Location Details'}
        expandable
      >
        <SectionItem label={'Location ID'}>
          {locationDetails?.location_id}
        </SectionItem>
        <SectionItem label={'Store Name'}>
          {locationDetails?.loc_name}
        </SectionItem>
        <SectionItem label={'Address'}>
          {locationDetails?.loc_address}
        </SectionItem>
        <SectionItem label={'City'}>{locationDetails?.loc_city}</SectionItem>
        <SectionItem label={'State'}>{locationDetails?.loc_state}</SectionItem>
      </Section>
    </BottomBorderDiv>
  )
}
