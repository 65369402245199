import {
  Button,
  Form,
  Grid,
  Modal,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import * as Yup from 'yup'
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik'
import { useAuth } from '@praxis/component-auth'
import { SET_FIELD } from '../../globalConfig/common/ConstantData'
import { deriveBudgetAmount } from './CommonUtil'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useState } from 'react'
import _ from 'lodash'

export const EditBudgetExpenses = () => {
  const { session } = useAuth()
  const [state, dispatch] = useGlobalForm()
  const [isSaveAndClose, setIsSaveAndClose] = useState(false)

  const initialValues = {
    expenseTypeDescr: _.startsWith(
      state.editedBudgetExpense.expense_type_descr,
      'Others-'
    )
      ? 'Others'
      : state.editedBudgetExpense.expense_type_descr,
    expenseTypeName: state.editedBudgetExpense.expense_type_name,
    expenseType: state.editedBudgetExpense.expense_type,
    othersExpenseName: _.startsWith(
      state.editedBudgetExpense.expense_type_descr,
      'Others-'
    )
      ? _.split(state.editedBudgetExpense.expense_type_descr, 'Others-', 2)[1]
      : '',
    totalExpense: state.editedBudgetExpense.total_expense_amount,
    prs: state.editedBudgetExpense.prs,
    prsAmount: state.editedBudgetExpense.prs_amount,
    adminFee: state.editedBudgetExpense.admin_fee,
    adminAmount: state.editedBudgetExpense.admin_amount,
    annualAmount: state.editedBudgetExpense.annual_amount,
    monthlyAmount: state.editedBudgetExpense.monthly_amount,
  }

  const handleClose = () => {
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'isEditBudgetExpenseShowable', value: false }],
    })
  }

  const getExpenseType = async (expenseTypeDescr) => {
    let filteredExpenseTypeList = state.expensePool?.filter(
      (value) => value?.expense_type_descr === expenseTypeDescr
    )
    if (filteredExpenseTypeList.length > 0) {
      formik1.setFieldValue(
        'expenseType',
        filteredExpenseTypeList[0].expense_type
      )
    } else {
      formik1.setFieldValue('expenseType', '')
    }
  }

  const schema = Yup.object({
    totalExpense: Yup.number().required('Please Enter Total Expense'),
    prs: Yup.number()
      .required('Please Enter PRS %')
      .test('Fine', 'PRS should not be more than 100%', function (value) {
        if (value > 100) {
          return false
        }
        return true
      }),
    adminFee: Yup.number()
      .required('Please Enter Admin Fee %')
      .test('Fine', 'Admin Fee should not be more than 100%', function (value) {
        if (value > 100) {
          return false
        }
        return true
      }),
  })

  const onSubmit = (values) => {
    // find whether edit happens on expense that exists in db or user edits the expense that is recently added
    // and not yet saved into db.
    var index = -1
    index = state.budgetData?.budget_expenses?.findIndex(
      (existingBudgetExpense) => {
        return (
          existingBudgetExpense.expense_type_descr ===
          state.editedBudgetExpense.expense_type_descr
        )
      }
    )

    const newBudgetExpense = {
      budget_expense_id:
        state.editedBudgetExpense.budget_expense_id !== undefined
          ? state.editedBudgetExpense.budget_expense_id
          : '', // added to fix issue
      expense_type: values.expenseType,
      expense_type_descr:
        values.othersExpenseName !== ''
          ? values.expenseTypeDescr + '-' + values.othersExpenseName
          : values.expenseTypeDescr,
      expense_type_name: values.expenseTypeName,
      total_expense_amount: Number(values.totalExpense),
      prs: Number(values.prs),
      prs_amount: Number(values.prsAmount),
      admin_fee: Number(values.adminFee),
      admin_amount: Number(values.adminAmount),
      annual_amount: Number(values.annualAmount),
      monthly_amount: Number(values.monthlyAmount),
      create_user_id: state.editedBudgetExpense.create_user_id,
      creatae_ts: state.editedBudgetExpense.create_ts,
      update_user_id: index >= 0 ? session.userInfo.lanId : null,
      update_ts: index >= 0 ? new Date().toISOString() : null,
    }

    // replace the edited expense in the list of expenses
    var budgetExpenses = [...state.budgetExpenses]
    var editedData = budgetExpenses.map(
      (obj) =>
        [newBudgetExpense].find(
          (o) => o.expense_type_descr === obj.expense_type_descr
        ) || obj
    )

    dispatch({
      type: SET_FIELD,
      payload: [
        {
          id: 'budgetExpenses',
          value: editedData,
        },
        {
          id: 'isBudgetChanged',
          value: true,
        },
      ],
    })
    formik1.resetForm()
    setIsSaveAndClose(false)
    if (isSaveAndClose) {
      handleClose()
    }
  }

  const formik1 = useFormik({
    initialValues,
    onSubmit,
    enableReinitialize: true,
    validationSchema: schema,
    validate: (values) => {
      const validationSchema = schema
      try {
        validateYupSchema(values, validationSchema, true, values)
      } catch (err) {
        return yupToFormErrors(err)
      }
      return {}
    },
  })

  return (
    <Modal
      headingText="Edit Budget Expense"
      className="myModal"
      size="dense"
      isVisible={state.isEditBudgetExpenseShowable}
      onRefuse={() => {
        formik1.resetForm()
        handleClose()
      }}
    >
      <Form onSubmit={formik1.handleSubmit}>
        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container justify="center">
            <Grid.Item xs={12} className="hc-pb-none">
              <Autocomplete
                id="expenseTypeDescr"
                options={state.expenseTypeOptions}
                label="Expense Type Descr"
                onUpdate={(id, value) => {
                  formik1.setFieldValue(id, value?.value)
                  getExpenseType(value?.value)
                }}
                value={
                  formik1.values.expenseTypeDescr
                    ? {
                        id: formik1.values.expenseTypeDescr,
                        value: formik1.values.expenseTypeDescr,
                        label: formik1.values.expenseTypeDescr,
                      }
                    : null
                }
                optionHeight="dense"
                disabled
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        {formik1.values.othersExpenseName && (
          <Grid.Item xs={12} className="hc-pa-normal">
            <Grid.Container justify="center">
              <Grid.Item xs={12} className="hc-pb-none">
                <Form.Field
                  id={'othersExpenseName'}
                  label={'Expense Name'}
                  type="text"
                  onChange={(e) =>
                    formik1.setFieldValue('othersExpenseName', e.target.value)
                  }
                  value={formik1.values.othersExpenseName || ''}
                  disabled
                  onBlur={(e) => {
                    e.preventDefault()
                    deriveBudgetAmount(formik1)
                  }}
                />
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        )}

        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Item xs={12} className="hc-pb-none">
            <Form.Field
              id={'expenseTypeName'}
              label={'Expense Type Name'}
              type="text"
              value={formik1.values.expenseTypeName || ''}
              disabled={true}
            />
          </Grid.Item>
        </Grid.Item>

        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs={6} className="hc-pb-none">
              <Form.Field
                id={'expenseType'}
                label={'Expense Type'}
                type="number"
                value={formik1.values.expenseType || ''}
                disabled={true}
              />
            </Grid.Item>
            <Grid.Item xs={6} className="hc-pb-none">
              <Input.Label htmlFor="total_expense_lbl">
                Total Expense
              </Input.Label>
              <Input.Currency
                id="totalExpense"
                display={formik1.values.currencyType}
                value={formik1.values.totalExpense}
                onChange={(event) => {
                  formik1.setFieldValue(
                    'totalExpense',
                    event.target.value?.replace(/[^0-9.-]+/g, '')
                  )
                }}
                onBlur={(e) => {
                  e.preventDefault()
                  deriveBudgetAmount(formik1)
                }}
                allowDecimals
              />
              {formik1.touched.totalExpense &&
                formik1.errors.totalExpense !== undefined && (
                  <Input.Label
                    htmlFor="total_expense_err"
                    className="hc-clr-error hc-mt-min"
                  >
                    {formik1.errors.totalExpense}
                  </Input.Label>
                )}
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs={6} className="hc-pb-none">
              <Form.Field
                id={'prs'}
                label={'PRS (%)'}
                type="number"
                className="inputtype"
                onChange={(e) => formik1.setFieldValue('prs', e.target.value)}
                onBlur={(e) => {
                  e.preventDefault()
                  deriveBudgetAmount(formik1)
                }}
                value={formik1.values.prs || ''}
                error={formik1.touched.prs && formik1.errors.prs !== undefined}
                errorText={formik1.errors.prs}
              />
            </Grid.Item>
            <Grid.Item xs={6} className="hc-pb-none">
              <Input.Label htmlFor="prs_amount_lbl">PRS Amount</Input.Label>
              <Input.Currency
                id="prsAmount"
                display={formik1.values.currencyType}
                value={formik1.values.prsAmount}
                allowDecimals
                disabled
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs={6} className="hc-pb-none">
              <Form.Field
                id={'adminFee'}
                label={'Admin Fee (%)'}
                type="number"
                className="inputtype"
                onChange={(e) =>
                  formik1.setFieldValue('adminFee', e.target.value)
                }
                onBlur={(e) => {
                  e.preventDefault()
                  deriveBudgetAmount(formik1)
                }}
                value={formik1.values.adminFee || ''}
                error={
                  formik1.touched.adminFee &&
                  formik1.errors.adminFee !== undefined
                }
                errorText={formik1.errors.adminFee}
              />
            </Grid.Item>
            <Grid.Item xs={6} className="hc-pb-none">
              <Input.Label htmlFor="admin_amount_lbl">Admin Amount</Input.Label>
              <Input.Currency
                id="adminAmount"
                display={formik1.values.currencyType}
                value={formik1.values.adminAmount}
                allowDecimals
                disabled
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs={6} className="hc-pb-none">
              <Input.Label htmlFor="annual_amount_lbl">
                Annual Amount
              </Input.Label>
              <Input.Currency
                id="annualAmount"
                display={formik1.values.currencyType}
                value={formik1.values.annualAmount}
                allowDecimals
                disabled
              />
            </Grid.Item>

            <Grid.Item xs={6} className="hc-pb-none">
              <Input.Label htmlFor="monthly_amount_lbl">
                Monthly Amount
              </Input.Label>
              <Input.Currency
                id="monthlyAmount"
                display={formik1.values.currencyType}
                value={formik1.values.monthlyAmount}
                allowDecimals
                disabled
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item justify="flex-end" xs={12}>
              <Grid.Container direction="row-reverse" spacing="dense">
                <Grid.Item>
                  <Button
                    type="submit"
                    onClick={() => {
                      setIsSaveAndClose(false)
                    }}
                  >
                    Save and Add More
                  </Button>
                </Grid.Item>

                <Grid.Item>
                  <Button
                    type="submit"
                    onClick={() => {
                      setIsSaveAndClose(true)
                    }}
                  >
                    Save and close
                  </Button>
                </Grid.Item>

                <Grid.Item>
                  <Button
                    onClick={() => {
                      formik1.resetForm()
                      handleClose()
                    }}
                  >
                    Cancel
                  </Button>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Form>
    </Modal>
  )
}
