import { useEnv } from '@praxis/component-runtime-env'
import axios from 'axios'
// import { Vendor } from '../../globalComponents/UserProfile/UserProfileContext'

export const useVendorApi = () => {
  const env = useEnv()

  const getVendorList = async (businessPartnerId) => {
    return await axios
      .get(
        `${env.camsCoreApiUrl}/business_partner/${businessPartnerId}?key=${env.apiKey}`
      )
      .then((res) =>
        res.data.map((vendor) => ({
          vendorNo: Number(vendor.gmsVendorNumber),
          vendorName: vendor.vendorName,
        }))
      )
  }

  return { getVendorList }
}
