export const excelDownloadHeadersBudgetTm = [
  { label: 'Budget ID #', key: 'budget_id' },
  { label: 'Budget created date', key: 'create_ts' },
  { label: 'Last updated date', key: 'update_ts' },
  { label: 'Document Type', key: 'doc_type' },
  { label: 'Fiscal Year', key: 'year' },
  { label: 'Contract No', key: 'contract_no' },
  { label: 'Contract Name', key: 'contract_name' },
  { label: 'Location Id', key: 'location_id' },
  { label: 'Location Name', key: 'loc_name' },
  { label: 'Location Address', key: 'loc_address' },
  { label: 'Location City', key: 'loc_city' },
  { label: 'Location State', key: 'loc_state' },
  { label: 'Status', key: 'status' },
  { label: 'Workflow', key: 'workflow' },
  { label: 'Vendor #', key: 'vendor_no' },
  { label: 'Vendor Name', key: 'vendor_name' },
  { label: 'Submitted by Name', key: 'submitted_by_name' },
  { label: 'Submitted by Email', key: 'submitted_by_email' },
  { label: 'Total Submitted Expense $', key: 'total_amount' },
  { label: 'Target Share - Annual $', key: 'tgt_annual_amount' },
  { label: 'Target Share - Monthly $', key: 'tgt_monthly_amount' },
  { label: 'Current Assignee', key: 'assigned_user_name' },
  { label: 'Budget Reopened', key: 'is_reopen' },
  { label: 'Budget Reopen Reason', key: 'reopen_reason' },
]

export const excelDownloadHeadersBudgetVendors = [
  { label: 'Budget ID #', key: 'budget_id' },
  { label: 'Budget created date', key: 'create_ts' },
  { label: 'Last updated date', key: 'update_ts' },
  { label: 'Document Type', key: 'doc_type' },
  { label: 'Fiscal Year', key: 'year' },
  { label: 'Contract No', key: 'contract_no' },
  { label: 'Contract Name', key: 'contract_name' },
  { label: 'Location Id', key: 'location_id' },
  { label: 'Location Name', key: 'loc_name' },
  { label: 'Location Address', key: 'loc_address' },
  { label: 'Location City', key: 'loc_city' },
  { label: 'Location State', key: 'loc_state' },
  { label: 'Status', key: 'status' },
  { label: 'Workflow', key: 'workflow' },
  { label: 'Vendor #', key: 'vendor_no' },
  { label: 'Vendor Name', key: 'vendor_name' },
  { label: 'Submitted by Name', key: 'submitted_by_name' },
  { label: 'Submitted by Email', key: 'submitted_by_email' },
  { label: 'Total Submitted Expense $', key: 'total_amount' },
  { label: 'Target Share - Annual $', key: 'tgt_annual_amount' },
  { label: 'Target Share - Monthly $', key: 'tgt_monthly_amount' },
  { label: 'Budget Reopened', key: 'is_reopen' },
  { label: 'Budget Reopen Reason', key: 'reopen_reason' },
]

export const excelDownloadHeadersActualsTm = [
  { label: 'Actual ID #', key: 'actual_id' },
  { label: 'Actual created date', key: 'create_ts' },
  { label: 'Last updated date', key: 'update_ts' },
  { label: 'Fiscal Year', key: 'year' },
  { label: 'Contract No', key: 'contract_no' },
  { label: 'Contract Name', key: 'contract_name' },
  { label: 'Location Id', key: 'location_id' },
  { label: 'Location Name', key: 'loc_name' },
  { label: 'Location Address', key: 'loc_address' },
  { label: 'Location City', key: 'loc_city' },
  { label: 'Location State', key: 'loc_state' },
  { label: 'Status', key: 'status' },
  { label: 'Workflow', key: 'workflow' },
  { label: 'Vendor #', key: 'vendor_no' },
  { label: 'Vendor Name', key: 'vendor_name' },
  { label: 'Submitted by Name', key: 'submitted_by_name' },
  { label: 'Submitted by Email', key: 'submitted_by_email' },
  { label: 'Total Submitted Expense $', key: 'total_amount' },
  { label: 'Target Share - Annual $', key: 'tgt_annual_amount' },
  { label: 'Tax Rate (%)', key: 'tax_rate' },
  { label: 'Tax Amount $', key: 'tax_amount' },
  { label: 'Target Share Annual - Incl. Taxes $', key: 'total_due_amount' },
  { label: 'Prior Payment Amount $', key: 'prior_payment_amount' },
  { label: 'Prior Tax Payment Amount $', key: 'prior_tax_payment_amount' },
  { label: 'Final Due Amount $', key: 'final_due_amount' },
  { label: 'Current Assignee', key: 'assigned_user_name' },
  { label: 'Actual Reopened', key: 'is_reopen' },
  { label: 'Actual Reopen Reason', key: 'reopen_reason' },
]

export const excelDownloadHeadersActualsVendors = [
  { label: 'Actual ID #', key: 'actual_id' },
  { label: 'Actual created date', key: 'create_ts' },
  { label: 'Last updated date', key: 'update_ts' },
  { label: 'Fiscal Year', key: 'year' },
  { label: 'Contract No', key: 'contract_no' },
  { label: 'Contract Name', key: 'contract_name' },
  { label: 'Location Id', key: 'location_id' },
  { label: 'Location Name', key: 'loc_name' },
  { label: 'Location Address', key: 'loc_address' },
  { label: 'Location City', key: 'loc_city' },
  { label: 'Location State', key: 'loc_state' },
  { label: 'Status', key: 'status' },
  { label: 'Workflow', key: 'workflow' },
  { label: 'Vendor #', key: 'vendor_no' },
  { label: 'Vendor Name', key: 'vendor_name' },
  { label: 'Submitted by Name', key: 'submitted_by_name' },
  { label: 'Submitted by Email', key: 'submitted_by_email' },
  { label: 'Total Submitted Expense $', key: 'total_amount' },
  { label: 'Target Share - Annual $', key: 'tgt_annual_amount' },
  { label: 'Tax Rate (%)', key: 'tax_rate' },
  { label: 'Tax Amount $', key: 'tax_amount' },
  { label: 'Target Share Annual - Incl. Taxes $', key: 'total_due_amount' },
  { label: 'Prior Payment Amount $', key: 'prior_payment_amount' },
  { label: 'Prior Tax Payment Amount $', key: 'prior_tax_payment_amount' },
  { label: 'Final Due Amount $', key: 'final_due_amount' },
  { label: 'Actual Reopened', key: 'is_reopen' },
  { label: 'Actual Reopen Reason', key: 'reopen_reason' },
]
