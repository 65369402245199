import styled from '@emotion/styled'
import { css } from '@emotion/react'

export const BorderDiv = styled.div`
  border: 1px solid;
  border-color: #d6d6d6;
  css: ${(props) =>
    props.scrollable ? summaryDivCss : props.divScroll ? stationaryScroll : ''};
`

export const BottomBorderDiv = styled.div`
  border-bottom: 1px solid;
  border-color: #d6d6d6;
`
const summaryDivCss = css`
  height: 82%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  position: -webkit-sticky;
  position: sticky;
  top: 91px;
`

const stationaryScroll = css`
  width: 200px;
  height: 500px;
  overflow-y: scroll;
`

export const button_card = css`
  border-radius: 0px;
`

export const cellWidth = css`
  width: 20px;
`
export const editButtons = css`
  height: 30px;
  text-transform: capitalize;
  margin-left: 7px;
  border: 0px;
`
export const editButtonsGroup = css`
  height: 30px;
  text-transform: capitalize;
  margin-left: 3px;
  border: 0px;
`
export const assignButtonStyle = css`
  color: #cc0000;
  font-size: 10px;
  background: white;
  border: none;
`
