import { useEffect } from 'react'
import Cookies from 'js-cookie'
import decode from 'jwt-decode'

const SESSION_COOKIE = 'SSOSESSIONINFO'
const ACCESS_TOKEN = 'access_token'
const COOKIE_POLL_INTERVAL = 2000

//This is added to support the 'switch company' feature in Partnersonline portal.
export default function SessionCookieHandler({ children }) {
  useEffect(() => {
    validateAuthToken()
    cookieChangeListener(SESSION_COOKIE, cookieChangeHandler)
  })
  return <>{children}</>
}

const validateAuthToken = () => {
  if (isAccessTokenAlive()) {
    const idTokenUser = getIdTokenUser()
    const sessionUser = getSessionCookieUser(Cookies.get(SESSION_COOKIE))
    if (idTokenUser !== undefined && sessionUser !== undefined) {
      if (idTokenUser === sessionUser) {
        return
      }
      clearLocalStorage()
    }
  }
}
const getSessionCookieUser = (cookie) => {
  if (cookie) {
    const SSOSESSIONINFOcoded = window.atob(cookie)
    const SSOSESSIONINFOJson = JSON.parse(SSOSESSIONINFOcoded)
    return String(SSOSESSIONINFOJson.user)
  }
}
const getIdTokenUser = () => {
  const userToken = localStorage.getItem(ACCESS_TOKEN)
  let decoded_token = decode(userToken)
  if (decoded_token) {
    return decoded_token['username']
  }
}

const isAccessTokenAlive = () => {
  const userToken = localStorage.getItem(ACCESS_TOKEN)
  if (userToken) {
    const tokenExpUTCSeconds = decode(userToken).exp
    const currentTimeUTCSeconds = Math.floor(Date.now() / 1000)
    return tokenExpUTCSeconds > currentTimeUTCSeconds
  }
  return false
}

const cookieChangeHandler = (prevCookie, currCookie) => {
  if (prevCookie !== undefined && currCookie !== undefined) {
    const olderSessionUser = getSessionCookieUser(prevCookie)
    const newSessionUser = getSessionCookieUser(currCookie)
    if (olderSessionUser !== newSessionUser) {
      clearLocalStorage()
    }
  }
}

const cookieChangeListener = (cookieName, cookieChangeHandler) => {
  let lastCookie = Cookies.get(cookieName)
  setInterval(() => {
    let currentCookie = Cookies.get(cookieName)
    if (currentCookie !== lastCookie) {
      cookieChangeHandler(lastCookie, currentCookie)
      lastCookie = currentCookie
    }
  }, COOKIE_POLL_INTERVAL)
}

const clearLocalStorage = () => {
  window.localStorage.removeItem('access_token')
  window.localStorage.removeItem('environment')
  window.localStorage.removeItem('id_token')
  window.location.reload()
}
