import { Grid, Modal, Button, Heading } from '@enterprise-ui/canvas-ui-react'
import '../Budget/Custom.css'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { SET_FIELD } from '../../globalConfig/common/ConstantData'

export const ErrorModal = ({ headingText }) => {
  const [state, dispatch] = useGlobalForm()
  return (
    <div>
      <Modal
        className="hc-clr-alert"
        headingText={headingText}
        isVisible={state.isShowErrorModal}
        onRefuse={() => {
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'isShowErrorModal', value: false }],
          })
        }}
      >
        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs>
              <Heading size={6} className="hc-clr-alert">
                {state.errorMsg}
              </Heading>
            </Grid.Item>
          </Grid.Container>
          <Grid.Container direction="row-reverse" spacing="dense">
            <Grid.Item>
              <Button
                type="secondary"
                onClick={() => {
                  dispatch({
                    type: SET_FIELD,
                    payload: [{ id: 'isShowErrorModal', value: false }],
                  })
                }}
              >
                Ok
              </Button>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Modal>
    </div>
  )
}

export default ErrorModal
