import { Grid, Heading, Form } from '@enterprise-ui/canvas-ui-react'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { SET_FIELD } from '../../globalConfig/common/ConstantData'
import '../Budget/Custom.css'

export const ContractDetails = ({ formik, getContractNumber }) => {
  const [state, dispatch] = useGlobalForm()
  const formData = formik?.values

  function clearValues() {
    formik.setErrors({})
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'vendorIdSearch', value: false }],
    })
  }

  const generalHeading = 'Choose Contract Details'

  return (
    <Grid.Container className="hc-pt-expanded hc-ml-min">
      <Heading size={6}>{generalHeading} </Heading>
      <Grid.Container className="hc-pt-normal hc-mr-normal">
        <Grid.Item xs={10} md={3}>
          <Form.Field
            id="contractName"
            label="Contract /Agreement Name"
            type="select"
            className="hc-ta-left"
            onUpdate={formik.setFieldValue}
            value={formData.contractName || ''}
            error={formik.errors.contractName !== undefined}
            errorText={formik.errors.contractName}
            options={state.contractOptions}
            required
            onBlur={(e) => {
              e.preventDefault()
              clearValues(e)
              getContractNumber()
            }}
          />
        </Grid.Item>
        {formData.contractNo && (
          <Grid.Item xs={10} md={3}>
            <Form.Field
              id="contractNo"
              label="Contract Number"
              className="hc-ta-left"
              value={formData.contractNo}
              disabled={true}
            />
          </Grid.Item>
        )}
      </Grid.Container>
    </Grid.Container>
  )
}

export default ContractDetails
