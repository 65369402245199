export const errorMsg =
  'Make sure you are connected to the VPN or Please try again later.'

export const contractStatusOptions = [
  {
    label: 'Active',
    value: 'A',
  },
  {
    label: 'Inactive',
    value: 'I',
  },
]

export const regionOptions = [
  {
    label: '100',
    value: 100,
  },
  {
    label: '200',
    value: 200,
  },
  {
    label: '300',
    value: 300,
  },
  {
    label: '400',
    value: 400,
  },
  {
    label: ' ',
    value: 0,
  },
]

export const pageSizeOptions = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '25',
    value: 25,
  },
  {
    label: '50',
    value: 50,
  },
]

export const BudgetStatus = {
  NOT_STARTED: 'Not Started',
  IN_PROGRESS: 'In Progress',
  MORE_INFO_REQUIRED: 'More Info Required',
  SUBMITTED_FOR_APPROVAL: 'Submitted for Approval',
  APPROVED: 'Approved',
  APPROVED_BY_SITEOPS: 'Approved By SiteOps',
  DENIED: 'Denied',
  REOPENED: 'Reopened',
}

export const ActualStatus = {
  NOT_STARTED: 'Not Started',
  IN_PROGRESS: 'In Progress',
  MORE_INFO_REQUIRED: 'More Info Required',
  SUBMITTED_FOR_APPROVAL: 'Submitted for Approval',
  APPROVED: 'Approved',
  APPROVED_BY_SITEOPS: 'Approved By SiteOps',
  DENIED: 'Denied',
  REOPENED: 'Reopened',
}

export const Workflow = {
  VENDOR: 'VENDOR',
  PAA: 'PAA',
  PAM: 'PAM',
  SITEOPS: 'SITEOPS',
}

// global form types
export const SET_FIELD = 'SET_FIELD'
export const RESET = 'RESET'

// Budget Update
export const WORKFLOW = 'Workflow Updated'
export const STATUS = 'Status Updated'
export const BUDGET_STATUS = 'Budget expenses are updated'
export const WORKFLOW_STATUS = 'Workflow and Status Updated'
export const ASSIGNEE_STATUS = 'Assignee Updated'
export const REOPEN = 'Budget is Reopened'
export const ATTACHMENTS = 'Documents Uploaded'
export const COMMENTS = 'Work Note Added'
export const ASSIGNED_TO_TGT = 'Budget is assigned back to Target'
export const ACTUAL_STATUS = 'Actual expenses are updated'
export const REOPEN_ACTUAL = 'Actual is Reopened'
export const ASSIGNED_TO_TGT_ACTUAL = 'Actual is assigned back to Target'

export const PAM_SITEOPS_WORKFLOW = [Workflow.PAM, Workflow.SITEOPS]
export const PAA_VENDOR_WORKFLOW = [Workflow.PAA, Workflow.VENDOR]
export const PAA_WORKFLOW = [Workflow.PAA]
export const PAM_WORKFLOW = [Workflow.PAM]
export const SITEOPS_WORKFLOW = [Workflow.SITEOPS]

export const Security = {
  INTERNAL: 'INTERNAL',
  EXTERNAL: 'EXTERNAL',
  BOTH: 'BOTH',
}

export const BudgetLandingCards = [
  {
    status: BudgetStatus.NOT_STARTED,
    text: 'Not Started',
    count: undefined,
  },
  {
    status: BudgetStatus.IN_PROGRESS,
    text: 'In Progress',
    count: undefined,
  },
  {
    status: BudgetStatus.MORE_INFO_REQUIRED,
    text: 'More Info Required',
    count: undefined,
  },
  {
    status: BudgetStatus.SUBMITTED_FOR_APPROVAL,
    text: 'Submitted for Approval',
    count: undefined,
  },
  {
    status: BudgetStatus.APPROVED,
    text: 'Submitted for Approval',
    count: undefined,
  },
  {
    status: BudgetStatus.APPROVED_BY_SITEOPS,
    text: 'Submitted for Approval',
    count: undefined,
  },
  {
    status: BudgetStatus.DENIED,
    text: 'Submitted for Approval',
    count: undefined,
  },
  {
    status: BudgetStatus.REOPENED,
    text: 'Submitted for Approval',
    count: undefined,
  },
]
