import { Text, View } from '@react-pdf/renderer'
import { styles } from '../PDFExports/Styles'

// Define the styles object outside the component
const headerStyles = {
  textAlign: 'right',
  fontSize: '10px',
  padding: '7px',
  fontFamily: 'Helvetica-Bold',
  color: 'grey',
}

const BudgetTableHeader = () => (
  <View style={styles.tableRow} fixed wrap={false}>
    <Text style={{ ...headerStyles, width: '110px', textAlign: 'left' }}>
      Expense Type
    </Text>
    <Text style={{ ...headerStyles, width: '150px', textAlign: 'left' }}>
      Expense Type Name
    </Text>
    <Text style={{ ...headerStyles, width: '100px' }}>GL Account</Text>
    <Text style={{ ...headerStyles, width: '125px' }}>
      Total Expense Amount
    </Text>
    <Text style={{ ...headerStyles, width: '70px' }}>PRS %</Text>
    <Text style={{ ...headerStyles, width: '125px' }}>PRS Amount</Text>
    <Text style={{ ...headerStyles, width: '70px' }}>Admin Fee %</Text>
    <Text style={{ ...headerStyles, width: '125px' }}>Admin Amount</Text>
    <Text style={{ ...headerStyles, width: '125px' }}>Annual Amount</Text>
    <Text style={{ ...headerStyles, width: '125px' }}>
      Target Share - Monthly
    </Text>
  </View>
)

export default BudgetTableHeader
