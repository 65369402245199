import { Grid, Modal, Button, Heading } from '@enterprise-ui/canvas-ui-react'
import '../Actual/Custom.css'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { SET_FIELD } from '../../globalConfig/common/ConstantData'

export const DeleteActualExpenses = ({ headingText, heading }) => {
  const [state, dispatch] = useGlobalForm()

  const handleDeletedExpense = () => {
    const values = [...state.actualExpenses]

    // find whether Delete happens on expense that exists in db or user delete the expense that is recently added
    // and not yet saved into db.

    var index = state.actualData?.actual_expenses?.findIndex(
      (existingActualExpense) => {
        return (
          existingActualExpense.expense_type_descr ===
          state.deletedActualExpense.expense_type_descr
        )
      }
    )

    if (index >= 0) {
      var allDeletedExpenses = [
        ...state.deleteActualExpenses,
        state.deletedActualExpense,
      ]

      dispatch({
        type: SET_FIELD,
        payload: [
          {
            id: 'deletedActualExpenses',
            value: allDeletedExpenses,
          },
          {
            id: 'isActualDeleted',
            value: true,
          },
          {
            id: 'isActualChanged',
            value: true,
          },
        ],
      })
    }

    values.splice(
      values.findIndex(
        (value) =>
          value.expense_type_descr ===
          state.deletedActualExpense.expense_type_descr
      ),
      1
    )

    dispatch({
      type: SET_FIELD,
      payload: [
        { id: 'isDeleteActualExpenseShowable', value: false },
        { id: 'actualExpenses', value: values },
      ],
    })
  }

  return (
    <div>
      <Modal
        headingText={headingText}
        isVisible={state.isDeleteActualExpenseShowable}
        onRefuse={() => {
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'isDeleteActualExpenseShowable', value: false }],
          })
        }}
      >
        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs>
              <Heading size={6}>
                {' '}
                Do you want to delete the expense with Expense Type Descr:{' '}
                {state.deletedActualExpense.expense_type_descr} ?
              </Heading>
            </Grid.Item>
          </Grid.Container>
          <Grid.Container direction="row-reverse" spacing="dense">
            <Grid.Item>
              <Button type="primary" onClick={handleDeletedExpense}>
                Yes
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button
                type="secondary"
                onClick={() => {
                  dispatch({
                    type: SET_FIELD,
                    payload: [
                      { id: 'isDeleteActualExpenseShowable', value: false },
                    ],
                  })
                }}
              >
                No
              </Button>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Modal>
    </div>
  )
}

export default DeleteActualExpenses
