import qs from 'qs'
import _ from 'lodash'
import { Security } from '../../../globalConfig/common/ConstantData'
import moment from 'moment'

function buildUrl(history, payload) {
  let queryObject = qs.parse(history.search.replace('?', ''))

  const { filter, searchField, event, type } = payload
  //radio
  if (type === 'radio') {
    queryObject[filter] = event
  }

  if (type === 'search_group') {
    if (queryObject[filter] === undefined) {
      queryObject[filter] = { [searchField]: [...event.id.split(',')] }
    } else {
      queryObject[filter][searchField] = _.uniq([
        ...(queryObject[filter][searchField] ?? []),
        ...event.id.split(','),
      ])
    }
  }

  // checkbox
  if (type === 'checkbox') {
    if (queryObject[filter] === undefined) {
      queryObject[filter] = [event.target.value]
    } else {
      const filters = Object.values(queryObject[filter])
      const value = event.target.value
      if (filters.length === _.pull(filters, value).length) {
        filters.push(value)
      }
      queryObject[filter] = [...filters]
    }
  }

  // date ranges
  if (type === 'date') {
    queryObject[filter] = event
  }

  // main search
  if (type === 'search') {
    if (queryObject['search'] === undefined) {
      queryObject['search'] = [payload.id]
    } else {
      queryObject['search'] = _.uniq([...queryObject['search'], payload.id])
    }
  }
  return qs.stringify(queryObject)
}

function removeFilter(history, payload) {
  let queryObject = qs.parse(history.search.replace('?', ''), {
    arrayLimit: 100,
  })
  const { type, filter, searchFilter, id } = payload

  Object.keys(queryObject)
    .filter((key) => key === filter)
    .forEach((key) => {
      if (type === 'checkbox' || filter === 'search') {
        _.remove(queryObject[key], function (n) {
          return n === id
        })
      }

      if (type === 'date' || type === 'radio') {
        queryObject[key] = undefined
      }

      if (type === 'search_group') {
        _.remove(queryObject[key][searchFilter], function (n) {
          return n === id
        })
      }
    })

  return qs.stringify(queryObject)
}

const convertToUS = (isoDate) => {
  const split = isoDate?.split('-')

  if (split && split.length > 1) {
    return `${split[1]}/${split[2]}/${split[0]}`
  }
  return undefined
}

const convertToISO = (usDate) => {
  const split = usDate?.split('/')

  if (split && split.length > 1) {
    return `${split[2]}-${split[0]}-${split[1]}`
  } else {
    return usDate
  }
}

function handleDateRangeFilter(dateRange) {
  if (dateRange === 'now') {
    return moment().format('YYYY-MM-DD')
  }
  if (dateRange === 'now-7d') {
    return moment().subtract(7, 'days').format('YYYY-MM-DD')
  }
  if (dateRange === 'now-14d') {
    return moment().subtract(14, 'days').format('YYYY-MM-DD')
  }
  if (dateRange === 'now-30d') {
    return moment().subtract(30, 'days').format('YYYY-MM-DD')
  }
  return dateRange
}

const buildQuery = (history, state, vendor, lanId, type) => {
  let obj = qs.parse(history.search.replace('?', ''))
  if (obj['search'] != null) {
    obj = { ...obj, ...obj['search'] }
    delete obj['search']
  }

  if (obj['createDate'] != null) {
    obj['create_date_from'] = handleDateRangeFilter(obj['createDate']['from'])
    obj['create_date_to'] = handleDateRangeFilter(obj['createDate']['to'])
    delete obj['createDate']
  }

  if (obj['updateDate'] != null) {
    obj['update_date_from'] = handleDateRangeFilter(obj['updateDate']['from'])
    obj['update_date_to'] = handleDateRangeFilter(obj['updateDate']['to'])
    delete obj['updateDate']
  }

  if (obj['fiscal_year'] != null) {
    obj['year'] = obj['fiscal_year']['fiscal_year'][0]
    delete obj['fiscal_year']
  }

  if (obj['locationId'] != null) {
    obj['location_id'] = obj['locationId']['locationId'][0]
    delete obj['locationId']
  }

  if (obj['vendorNo'] != null) {
    obj['vendor_no'] = obj['vendorNo']['vendorNo'][0]
    delete obj['vendorNo']
  }

  if (obj['budgetId'] != null) {
    obj['budget_id'] = obj['budgetId']['budgetId'][0]
    delete obj['budgetId']
  }

  if (obj['actualId'] != null) {
    obj['actual_id'] = obj['actualId']['actualId'][0]
    delete obj['actualId']
  }

  if (obj['contractNo'] != null) {
    obj['contract_no'] = obj['contractNo']['contractNo'][0]
    delete obj['contractNo']
  }

  if (obj['contractName'] != null) {
    obj['contract_name'] = obj['contractName']['contractName'][0]
    delete obj['contractName']
  }
  return obj
}

function filterUser(key, isVendor) {
  if (isVendor) {
    return key.security === Security.EXTERNAL || key.security === Security.BOTH
  }
  return key.security === Security.INTERNAL || key.security === Security.BOTH
}

export {
  removeFilter,
  buildUrl,
  convertToISO,
  convertToUS,
  buildQuery,
  filterUser,
}
