import Section, { SectionItem } from '../Section'

export const ViewYearAndDocType = ({ genericDetails }) => {
  return (
    <Section
      labelGridSize={4}
      detail={false}
      heading={'Year And Document Type'}
      expandable
    >
      <SectionItem label={'Fiscal Year'}>{genericDetails?.year}</SectionItem>
      <SectionItem label={'Document Type'}>Actual</SectionItem>
    </Section>
  )
}
