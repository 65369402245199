import { Grid, Heading, Form, Spinner } from '@enterprise-ui/canvas-ui-react'
import { useGlobalForm } from '../Context/GlobalFormStateContext'

import '../Budget/Custom.css'

export const VendorDetails = ({ formik, handleVendorSearch, isVendor }) => {
  const formData = formik?.values
  const [state] = useGlobalForm()

  const vendorHeading = 'Choose the correct Vendor Details'

  return (
    <Grid.Container className="hc-pt-expanded hc-ml-min">
      <Heading size={6}>{vendorHeading}</Heading>

      <Grid.Container className="hc-pt-normal hc-mr-normal">
        <Grid.Item xs={10} md={3}>
          <Form.Field
            id="vendorNumber"
            label="Vendor ID"
            type="number"
            className="hc-ta-left inputtype"
            onChange={formik.handleChange}
            value={formik.values.vendorNumber || ''}
            error={formik.errors.vendorNumber !== undefined}
            errorText={formik.errors.vendorNumber}
            disabled={isVendor}
            required
            onBlur={(e) => {
              e.preventDefault()
              if (!isVendor) {
                handleVendorSearch()
              }
            }}
          />
        </Grid.Item>
        {state.loadingVendorData && (
          <Grid.Item className="hc-mt-expanded">
            <Spinner size="dense"></Spinner>
          </Grid.Item>
        )}
        {formData.vendorName && (
          <Grid.Item xs={10} md={3}>
            <Form.Field
              id="vendorName"
              label="Vendor Name"
              className="hc-ta-left"
              value={formData.vendorName}
              disabled={true}
            />
          </Grid.Item>
        )}
        <Grid.Item xs={10} md={3}>
          <Form.Field
            id="submittedByName"
            label="Submitted by - Name"
            className="hc-ta-left"
            type="text"
            value={formData.submittedByName}
            onChange={formik.handleChange}
            maxLength="35"
          />
        </Grid.Item>
        <Grid.Item xs={10} md={3}>
          <Form.Field
            id="submittedByEmailId"
            label="Submitted by - Email Address"
            className="hc-ta-left"
            type="text"
            value={formData.submittedByEmailId}
            onChange={formik.handleChange}
            maxLength="64"
            error={formik.errors.submittedByEmailId !== undefined}
            errorText={formik.errors.submittedByEmailId}
          />
        </Grid.Item>
      </Grid.Container>
    </Grid.Container>
  )
}

export default VendorDetails
