import Section, { SectionItem } from '../Section'

export const ViewReopenDetails = ({ reopenDetails }) => {
  return (
    <Section
      labelGridSize={4}
      detail={false}
      heading={'Reopen Details'}
      expandable
    >
      <SectionItem label={'Reopened'}>
        {reopenDetails?.is_reopen ? 'Yes' : 'No'}
      </SectionItem>
      <SectionItem label={'Reopen Reason'}>
        {reopenDetails?.reopen_reason}
      </SectionItem>
    </Section>
  )
}
