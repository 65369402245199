import React, { useEffect, useState } from 'react'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import '@enterprise-ui/canvas-ui-css-datepicker'
import { Input, Button, Divider, Popover } from '@enterprise-ui/canvas-ui-react'
import _ from 'lodash'
import { convertToISO, convertToUS } from '../SearchContext/SearchUtils'

export const FilterDate = ({ id, values, activeFilter, onChange }) => {
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [isVisible, setVisible] = useState()

  useEffect(() => {
    const activeFilterValue = _.filter(values, function (n) {
      return JSON.stringify(n.value) === JSON.stringify(activeFilter)
    })

    if (activeFilter && activeFilterValue.length < 1) {
      setStartDate(convertToUS(activeFilter.from))
      setEndDate(convertToUS(activeFilter.to))
    }
  }, [activeFilter, values])

  const filters = values.map((value) => ({
    ...value,
    value: JSON.stringify(value.value),
  }))

  return (
    <React.Fragment>
      <Input.Radio
        id={`${id}`}
        options={filters}
        onUpdate={(id, value) => {
          onChange(id, JSON.parse(value))
        }}
        value={JSON.stringify(activeFilter)}
      ></Input.Radio>
      <Divider />
      <div className="hc-pa-normal hc-ph-none">
        <Popover
          type="clickOnly"
          isVisible={isVisible}
          location="bottom-right"
          content={
            <div>
              <DatePicker
                id={`${id}_start_date`}
                label="Start Date"
                placeholder="MM/DD/YYYY"
                onUpdate={(id, value) => {
                  setStartDate(value)
                }}
                value={startDate ?? ''}
                location="bottom-right"
                className="date-picker"
              />
              <DatePicker
                id={`${id}_end_date`}
                label="End Date"
                placeholder="MM/DD/YYYY"
                onUpdate={(id, value) => {
                  setEndDate(value)
                }}
                value={endDate ?? ''}
                location="bottom-right"
                className="date-picker"
              />
              <Button
                onClick={() => {
                  setVisible(false)
                  onChange(id, {
                    from: convertToISO(startDate),
                    to: convertToISO(endDate),
                  })
                }}
                disabled={_.isEmpty(startDate) || _.isEmpty(endDate)}
              >
                Apply
              </Button>
            </div>
          }
        >
          <Button type="primary" onClick={() => setVisible(true)}>
            Between
          </Button>
        </Popover>
      </div>
    </React.Fragment>
  )
}
