import {
  Button,
  Grid,
  Modal,
  Spinner,
  Timeline,
} from '@enterprise-ui/canvas-ui-react'
import { useContext } from 'react'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { SET_FIELD } from '../../globalConfig/common/ConstantData'
import { useNavigate } from 'react-router'
import { AttachmentContext } from '../Context/AttachmentContext'
import { useAttachmentRequest } from '../hooks/useAttachmentRequest'

export const ViewSaveTimelineModal = ({
  saveAttachmentAuditEntry,
  redirectUrl,
  requestId,
  vendorNo,
  docType,
}) => {
  const [state, dispatch] = useGlobalForm()
  const context = useContext(AttachmentContext)
  const { retryAttachment } = useAttachmentRequest()
  const navigate = useNavigate()

  return (
    <Grid.Container className="hc-pa-normal">
      <Grid.Item className="hc-pa-normal">
        <Modal
          headingText={'Attachment Status'}
          isVisible={state.viewAttachmentStatus}
          onRefuse={() => {
            dispatch({
              type: SET_FIELD,
              payload: [{ id: 'viewAttachmentStatus', value: false }],
            })
          }}
        >
          <Timeline className="hc-pa-normal hc-ma-normal">
            {context.uploadedAttachments?.length !== 0 && (
              <>
                <Timeline.Flag>Uploading Attachments</Timeline.Flag>
                {context.uploadedAttachments.map((file, id) => (
                  <Timeline.Item key={id} type={file.status ?? 'default'}>
                    {!file.uploading && (
                      <p>
                        {file.name} &nbsp;
                        {file.status === 'error' &&
                        file.errorMessage === null ? (
                          <span
                            className="hc-clr-error"
                            style={{
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              retryAttachment(
                                requestId,
                                vendorNo,
                                file.name,
                                docType
                              )
                            }}
                          >
                            retry
                          </span>
                        ) : (
                          ''
                        )}
                        {file.status === 'error' &&
                        file.errorMessage !== null ? (
                          <span className="hc-clr-error">
                            {file.errorMessage}
                          </span>
                        ) : (
                          ''
                        )}
                      </p>
                    )}
                    {file.uploading && (
                      <>
                        <Spinner size="dense" /> &nbsp; Uploading {file.name}
                      </>
                    )}
                  </Timeline.Item>
                ))}
              </>
            )}
          </Timeline>{' '}
          <Grid.Container className="hc-pa-normal" spacing="dense">
            <Grid.Item>
              <Button
                type="secondary"
                className="hc-pa-normal hc-ma-normal"
                disabled={state.isSubmit}
                onClick={async () => {
                  dispatch({
                    type: SET_FIELD,
                    payload: [{ id: 'viewAttachmentStatus', value: false }],
                  })

                  let minOneUploaded = context.uploadedAttachments?.filter(
                    (value) => {
                      return value?.status === 'success'
                    }
                  )
                  if (minOneUploaded.length > 0) {
                    saveAttachmentAuditEntry()
                  } else {
                    navigate(redirectUrl)
                  }
                  context.setUploadedAttachments([])
                }}
              >
                OK
              </Button>
              {state.isSubmit && <Spinner size={'dense'} />}
            </Grid.Item>
          </Grid.Container>
        </Modal>
      </Grid.Item>
    </Grid.Container>
  )
}
export default ViewSaveTimelineModal
