import { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import { useCaseSearch } from '../SearchContext/SearchProvider'
import { GridItem, Button, Tooltip } from '@enterprise-ui/canvas-ui-react'
import {
  excelDownloadHeadersActualsTm,
  excelDownloadHeadersActualsVendors,
} from '../../Utils/DownloadHeaders'
import { CSVLink } from 'react-csv'
import { useEnv } from '@praxis/component-runtime-env'
import { useProfile } from '../../../globalComponents/UserProfileProvider'
import { buildQuery } from '../SearchContext/SearchUtils'
import { DownloadIcon, EnterpriseIcon } from '@enterprise-ui/icons'
import { useLocation } from 'react-router-dom'
import _ from 'lodash'
import moment from 'moment'

const ActualDownloadSearchResult = (props) => {
  const env = useEnv()
  const [state] = useCaseSearch()
  const { userProfile } = useProfile()
  const location = useLocation()
  const [downloadData, setDownloadData] = useState([])
  const [searchState, setSearchState] = useState('')
  const csvLink = useRef(null)

  useEffect(() => {
    if (downloadData.length > 0) {
      setTimeout(() => {
        csvLink?.current?.link.click()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadData])

  const downloadActuals = async () => {
    setDownloadData([])
    try {
      let query =
        buildQuery(
          location,
          state,
          userProfile.isVendor,
          userProfile.userId,
          'actual'
        ) ?? {}
      if (
        userProfile?.isVendor
        //  && query['vendorNo'] == undefined
      ) {
        query['vendor_no'] = userProfile.vendorList?.map(
          (vendor) => vendor?.vendorNo
        )
      }

      query['page'] = 1
      query['per_page'] = props.totalCases
      query['sort_column'] = props.sortColumn.id
      query['sort_order'] = props.sortAscending ? 'ASC' : 'DESC'

      //api call
      const response = await getActualsForAppliedFilters(query)
      const searchDownloadData = response.data?.content.map((row) => ({
        ...row,
        create_ts: row.create_ts
          ? moment(row.create_ts).format('MM/DD/YYYY')
          : '',
        update_ts: row.update_ts
          ? moment(row.update_ts).format('MM/DD/YYYY')
          : '',
        is_reopen: row.is_reopen ? 'Yes' : 'No',
      }))
      if (searchDownloadData !== null) {
        setSearchState('downloadSuccess')
        setDownloadData(searchDownloadData)
      } else {
        setSearchState('downloadNoData')
      }
      // setLoading(false)
    } catch (error) {
      console.log('Error in excel download', error)
      // setLoading(false)
      setSearchState('downloadError')
      //TODO Toaster message
      //TODO Loading spinner
    }
  }

  const getActualsForAppliedFilters = async (filtersApplied = {}) => {
    let formattedFilters = _.omitBy(filtersApplied, (v) => v == null)
    let urlParam = Object.entries(formattedFilters)
      .map((e) => e.join('='))
      .join('&')
    const res = await axios.get(
      `${env.camsCoreApiUrl}/actuals_search?key=${env.apiKey}&${urlParam}`
    )
    return res
  }

  return (
    <>
      <GridItem className="hc-ml-sm">
        <Tooltip content="Download" location="bottom">
          <Button
            iconOnly
            // style={{ backgroundColor: '#77b8fc' }}
            type="secondary"
            aria-label="Download Actuals"
            onClick={async () => {
              if (props.totalCases <= env.download.downloadSize) {
                downloadActuals()
              }
            }}
            disabled={
              searchState === 'actualDownload' ||
              props?.totalResults > env.download?.downloadSize
            }
          >
            <EnterpriseIcon icon={DownloadIcon} />
          </Button>
        </Tooltip>
        {downloadData && !userProfile?.isVendor ? (
          <CSVLink
            data={downloadData}
            headers={excelDownloadHeadersActualsTm}
            filename="actuals.csv"
            className="hidden"
            ref={csvLink}
            target="_blank"
          />
        ) : undefined}
        {downloadData && userProfile?.isVendor ? (
          <CSVLink
            data={downloadData}
            headers={excelDownloadHeadersActualsVendors}
            filename="actuals.csv"
            className="hidden"
            ref={csvLink}
            target="_blank"
          />
        ) : undefined}
      </GridItem>
    </>
  )
}

export default ActualDownloadSearchResult
