import { Grid, Spinner, Heading } from '@enterprise-ui/canvas-ui-react'

export function LoadingSpinner() {
  return (
    <div>
      <Grid.Container className="hc-pt-4x" justify="center">
        <Grid.Item className="hc-ta-center">
          <Spinner size="normal" />
        </Grid.Item>
        <Grid.Item className="hc-pt-md hc-ta-center">
          <Heading size={5}>Loading...</Heading>
        </Grid.Item>
      </Grid.Container>
    </div>
  )
}
