// import React from 'react'
import { Grid, Heading, Form, Spinner } from '@enterprise-ui/canvas-ui-react'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { SET_FIELD } from '../../globalConfig/common/ConstantData'
import '../Budget/Custom.css'

export const LocationDetails = ({ formik, handleLocationSearch }) => {
  const [state, dispatch] = useGlobalForm()
  const formData = formik?.values

  function clearValues() {
    formik.setErrors({})
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'isContractShowable', value: false }],
    })
  }

  const locationHeading = 'Choose the location details'

  return (
    <Grid.Container className="hc-pt-expanded hc-ml-min">
      <Heading size={6}>{locationHeading} </Heading>
      <Grid.Container className="hc-pt-normal hc-mr-normal">
        <Grid.Item xs={10} md={3}>
          <Form.Field
            id="locationId"
            label="Location"
            type="select"
            className="hc-ta-left"
            onUpdate={formik.setFieldValue}
            options={state.locationOptions}
            value={formData.locationId || ''}
            error={formik.errors.locationId !== undefined}
            errorText={formik.errors.locationId}
            required
            onBlur={(e) => {
              e.preventDefault()
              clearValues(e)
              handleLocationSearch()
            }}
          />
        </Grid.Item>
        {state.loadingLocationDetails && (
          <Grid.Item className="hc-mt-expanded">
            <Spinner size="dense"></Spinner>
          </Grid.Item>
        )}
        {state.isLocAttributesShowable && (
          <>
            <Grid.Item xs={10} md={3}>
              <Form.Field
                id="locName"
                label="Store Name"
                className="hc-ta-left"
                value={formData.locName}
                disabled={true}
              />
            </Grid.Item>
            <Grid.Item xs={10} md={3}>
              <Form.Field
                id="locAddress"
                label="Address"
                className="hc-ta-left"
                type="text"
                value={formData.locAddress}
                onChange={formik.handleChange}
                maxLength="35"
                disabled={true}
              />
            </Grid.Item>
            <Grid.Item xs={10} md={3}>
              <Form.Field
                id="locCity"
                label="City"
                className="hc-ta-left"
                type="text"
                value={formData.locCity}
                onChange={formik.handleChange}
                maxLength="64"
                error={formik.errors.locCity !== undefined}
                errorText={formik.errors.locCity}
                disabled={true}
              />
            </Grid.Item>
          </>
        )}
      </Grid.Container>
      <Grid.Container className="hc-pt-normal hc-mr-normal">
        {state.isLocAttributesShowable && (
          <Grid.Item xs={10} md={3}>
            <Form.Field
              id="locState"
              label="State"
              className="hc-ta-left"
              type="text"
              value={formData.locState}
              onChange={formik.handleChange}
              maxLength="35"
              disabled={true}
            />
          </Grid.Item>
        )}
      </Grid.Container>
    </Grid.Container>
  )
}

export default LocationDetails
