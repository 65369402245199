import {
  Avatar,
  Button,
  Dropdown,
  Grid,
  Layout,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { MenuIcon } from '@enterprise-ui/icons'
import { useAuth } from '@praxis/component-auth'
import { handleLogout } from '../views/AuthUtils'

const getInitials = (userInfo) =>
  `${userInfo.firstName.charAt(0)}${userInfo.lastName.charAt(0)}`

export const Header = ({ onSideNavToggle }) => {
  const auth = useAuth()
  const { session } = auth
  const company = session.userInfo?.company
  return (
    <>
      <Layout.MenuButton>
        <Button
          className="C-MenuButton"
          onClick={onSideNavToggle}
          iconOnly
          data-testid="headerMenuButton"
          aria-label="toggle side nav menu"
        >
          <EnterpriseIcon icon={MenuIcon} />
        </Button>
      </Layout.MenuButton>
      <Layout.Header />
      <Layout.GlobalActions>
        <Grid.Container justify="flex-end">
          <Grid.Item>
            <Dropdown size="dense" location="bottom-right">
              <Button
                aria-label="Click to open dropdown menu"
                iconOnly
                className="praxcss-avatarButton"
                data-testid="headerGlobalActionsButton"
              >
                <Avatar
                  aria-label={`Avatar showing the initials for ${session.userInfo.fullName}`}
                >
                  {getInitials(session.userInfo)}
                </Avatar>
              </Button>
              <Dropdown.Menu className="hc-bg-grey04">
                <Dropdown.MenuItem
                  data-testid="headerLogoutButton"
                  onClick={() => handleLogout(auth)}
                >
                  Log Out
                </Dropdown.MenuItem>
              </Dropdown.Menu>
            </Dropdown>
            &nbsp; {session.userInfo.fullName}, {company}
          </Grid.Item>
        </Grid.Container>
      </Layout.GlobalActions>
    </>
  )
}
