import { amountFormatter } from '../../views/Utils/NumericalFormatter'

function deriveBudgetAmount(formik) {
  var prsAmount,
    adminAmount,
    annualAmount,
    monthlyAmount = 0

  if (formik.values.totalExpense !== '') {
    formik.setFieldValue(
      'totalExpense',
      amountFormatter(parseFloat(formik.values.totalExpense))
    )
  }

  if (formik.values.prs !== '') {
    formik.setFieldValue('prs', amountFormatter(parseFloat(formik.values.prs)))
  }

  if (formik.values.adminFee !== '') {
    formik.setFieldValue(
      'adminFee',
      amountFormatter(parseFloat(formik.values.adminFee))
    )
  }

  if (formik.values.prs !== '' && formik.values.totalExpense !== '') {
    prsAmount = amountFormatter(
      formik.values.totalExpense * (formik.values.prs / 100)
    )
  }

  if (formik.values.adminFee !== '' && prsAmount !== 0) {
    adminAmount = amountFormatter(prsAmount * (formik.values.adminFee / 100))
    annualAmount = amountFormatter(
      parseFloat(prsAmount) + parseFloat(adminAmount)
    )
    monthlyAmount = amountFormatter(annualAmount / 12)
  }

  formik.setFieldValue('prsAmount', prsAmount)
  formik.setFieldValue('adminAmount', adminAmount)
  formik.setFieldValue('annualAmount', annualAmount)
  formik.setFieldValue('monthlyAmount', monthlyAmount)
}

function deriveActualAmount(formik) {
  var prsAmount,
    adminAmount,
    annualAmount,
    monthlyAmount = 0

  if (formik.values.totalExpense !== '') {
    formik.setFieldValue(
      'totalExpense',
      amountFormatter(parseFloat(formik.values.totalExpense))
    )
  }

  if (formik.values.prs !== '') {
    formik.setFieldValue('prs', amountFormatter(parseFloat(formik.values.prs)))
  }

  if (formik.values.adminFee !== '') {
    formik.setFieldValue(
      'adminFee',
      amountFormatter(parseFloat(formik.values.adminFee))
    )
  }

  if (formik.values.prs !== '' && formik.values.totalExpense !== '') {
    prsAmount = amountFormatter(
      formik.values.totalExpense * (formik.values.prs / 100)
    )
  }

  if (formik.values.adminFee !== '' && prsAmount !== 0) {
    adminAmount = amountFormatter(prsAmount * (formik.values.adminFee / 100))
    annualAmount = amountFormatter(
      parseFloat(prsAmount) + parseFloat(adminAmount)
    )
    monthlyAmount = amountFormatter(annualAmount / 12)
  }

  formik.setFieldValue('prsAmount', prsAmount)
  formik.setFieldValue('adminAmount', adminAmount)
  formik.setFieldValue('annualAmount', annualAmount)
  formik.setFieldValue('monthlyAmount', monthlyAmount)
}

function handleAmountFormatter(id, value, formik) {
  if (value !== '') {
    formik.setFieldValue(id, amountFormatter(parseFloat(value)))
  } else {
    formik.setFieldValue(id, value)
  }
}

export { deriveBudgetAmount, deriveActualAmount, handleAmountFormatter }
