import React from 'react'
import { BPCreateActual } from './BPCreateActual'
import { TMCreateActual } from './TMCreateActual'
import { useProfile } from '../../../globalComponents/UserProfileProvider'
import AttachmentContextProvider from '../../Context/AttachmentContext'

export const CreateActual = () => {
  const { userProfile } = useProfile()

  return (
    <React.Fragment>
      {userProfile?.isVendor ? (
        <AttachmentContextProvider>
          <BPCreateActual />
        </AttachmentContextProvider>
      ) : (
        <AttachmentContextProvider>
          <TMCreateActual />
        </AttachmentContextProvider>
      )}
    </React.Fragment>
  )
}
