import { Grid, Modal, Button } from '@enterprise-ui/canvas-ui-react'
import { useEffect, useState } from 'react'
import { SET_FIELD } from '../../../globalConfig/common/ConstantData'
import { useGlobalForm } from '../../Context/GlobalFormStateContext'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useCommonRequest } from '../../../globalComponents/hooks/useCommonRequest'

export const ViewAssignModal = ({ headingText, formik, saveAssignee }) => {
  const [state, dispatch] = useGlobalForm()
  const [assigneeOptions, setAssigneeOptions] = useState([])
  const { getAssigneeForAdGroup } = useCommonRequest()

  useEffect(() => {
    fetchAssignees()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.budgetWorkflow])

  const handleClose = () => {
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'showAssignModal', value: false }],
    })
  }

  const clearValues = () => {
    formik.setFieldValue('lanId', '')
    formik.setFieldValue('assignedUserName', '')
    formik.setFieldValue('workflow', '')
    formik.setFieldValue('adGroup', '')
  }

  async function fetchAssignees() {
    await getAssigneeForAdGroup(state.adGroup)
      .then((data) => {
        setAssigneeOptions(data)
      })
      .catch((error) => {
        console.log('Error fetching ldap member details', error)
        setAssigneeOptions([])
      })
  }

  const onAssgineeAssignment = () => {
    formik.validateForm().then((res) => {
      formik.setErrors({})
      const activityDescription = `${'Changed the assignee to '}${
        formik.values.assignedUserName
      }`

      dispatch({
        type: SET_FIELD,
        payload: [{ id: 'activityDescr', value: activityDescription }],
      })

      saveAssignee()
      handleClose()
    })
  }

  return (
    <div>
      <Modal
        headingText={headingText}
        className="myModal"
        size="dense"
        isVisible={state.showAssignModal}
        onRefuse={() => {
          clearValues()
          handleClose()
        }}
      >
        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container justify="center">
            <Grid.Item xs={6} className="hc-pb-none">
              <Autocomplete
                id="lanId"
                options={assigneeOptions}
                label="Assignee"
                onUpdate={(id, value) => {
                  formik.setFieldValue(id, value?.id)
                  formik.setFieldValue('assignedUserName', value?.label)
                }}
                optionHeight="dense"
                error={
                  formik.touched?.lanId && formik.errors?.lanId !== undefined
                }
                errorText={formik.errors?.lanId}
              />
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container direction="row-reverse" spacing="dense">
            <Grid.Item>
              <Button
                type="primary"
                disabled={
                  formik.values.lanId === '' ||
                  formik.values.lanId === undefined
                }
                onClick={onAssgineeAssignment}
              >
                Yes
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button
                type="secondary"
                onClick={() => {
                  clearValues()
                  handleClose()
                }}
              >
                No
              </Button>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Modal>
    </div>
  )
}
