import React from 'react'
import { BPCreateBudget } from './BPCreateBudget'
import { TMCreateBudget } from './TMCreateBudget'
// import { GlobalFormProvider } from '../../Context/GlobalFormStateContext'
import { useProfile } from '../../../globalComponents/UserProfileProvider'
import AttachmentContextProvider from '../../Context/AttachmentContext'

export const CreateBudget = () => {
  const { userProfile } = useProfile()

  return (
    <React.Fragment>
      {userProfile?.isVendor ? (
        <AttachmentContextProvider>
          <BPCreateBudget />
        </AttachmentContextProvider>
      ) : (
        <AttachmentContextProvider>
          <TMCreateBudget />
        </AttachmentContextProvider>
      )}
    </React.Fragment>
  )
}
// export default CreateBudget
