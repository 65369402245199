import {
  Button,
  Grid,
  Input,
  Heading,
  Table,
} from '@enterprise-ui/canvas-ui-react'
import { useContext } from 'react'
import { get } from 'lodash'
import { EnterpriseIcon, TrashIcon } from '@enterprise-ui/icons'
import { AttachmentContext } from '../Context/AttachmentContext'
import { useAttachmentRequest } from '../hooks/useAttachmentRequest'

const Attachments = () => {
  const { uploadedAttachments } = useContext(AttachmentContext)
  const { handleAttachments, removeAttachmentFromList } = useAttachmentRequest()

  const attachmentHeading = 'Have supporting documents? Attach here:'
  return (
    <>
      <Grid.Container className="hc-pt-expanded hc-ml-min">
        <Heading size={6}>{attachmentHeading}</Heading>
        <Grid.Container
          justify="left"
          className="hc-pt-expanded hc-ml-expanded"
        >
          <Grid.Item xs={6}>
            <Grid.Item>
              <Input.DropArea
                id="attach_files"
                dropText="Click Here to Upload Files"
                onUpdate={(event) => {
                  const files =
                    get(event, 'dataTransfer.files') ||
                    get(event, 'target.files')
                  handleAttachments(files)
                }}
                instructionText="Drag and Drop Files Here to Upload"
                fullwidth
                multiple
                accept={'.png, .jpg, .jpeg, .pdf, .csv, .gif, .xlsx, .docx, .msg'.split(
                  ', '
                )}
              />
              <Input.Label
                className="hc-fs-overline hc-clr-error hc-pt-normal"
                rightContent="*Allowed File Types: .png, .jpg, .jpeg, .pdf, .csv, .xlsx, .docx, .msg"
              />
            </Grid.Item>
            <Grid.Item>
              <Grid.Container className="hc-pt-none">
                <Grid.Item xs={12} md={12}>
                  {uploadedAttachments?.length !== 0 && (
                    <Table name="uploadedFiles">
                      <Table.Head>
                        <Table.Row justify="space-between">
                          <Table.Header xs={4}>File Name</Table.Header>
                          <Table.Header xs={1} />
                        </Table.Row>
                      </Table.Head>
                      <Table.Body>
                        {uploadedAttachments.map((file) => (
                          <Table.Row
                            justify="space-between"
                            key={file?.file?.id}
                          >
                            <Table.Data xs={4}>{file?.file?.name}</Table.Data>
                            <Table.Data xs={1}>
                              <Button
                                iconOnly
                                aria-label="remove attachment icon"
                                xs={1}
                                onClick={() => {
                                  removeAttachmentFromList(file)
                                }}
                              >
                                <EnterpriseIcon icon={TrashIcon} />
                              </Button>
                            </Table.Data>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  )}
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Item>
        </Grid.Container>
      </Grid.Container>
    </>
  )
}
export default Attachments
