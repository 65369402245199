import { StyleSheet } from '@react-pdf/renderer'

export const styles = StyleSheet.create({
  page: {
    backgroundColor: 'white',
    padding: 30,
    paddingTop: '20px',
    fontFamily: 'Helvetica',
  },
  topsection: {
    color: '#222222',
    textAlign: 'center',
  },
  section: {
    color: '#222222',
    textAlign: 'center',
    paddingTop: 20,
  },
  heading: {
    textAlign: 'left',
    fontSize: 16,
    fontWeight: 'heavy',
    fontFamily: 'Helvetica-Bold',
    paddingBottom: 20,
  },
  table: {
    display: 'flex',
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerInfo: {
    textAlign: 'left',
    width: '25%',
    fontSize: 12,
    fontFamily: 'Helvetica',
    paddingBottom: 5,
    color: 'grey',
  },
  headerValue: {
    textAlign: 'left',
    width: '25%',
    fontSize: 12,
    fontFamily: 'Helvetica',
    paddingBottom: 5,
    color: '#222222',
  },
  exportMetadata: {
    padding: '5px',
    bottom: 0,
    position: 'absolute',
    fontFamily: 'Helvetica',
    fontSize: '8px',
    textAlign: 'center',
    alignContent: 'center',
  },
  brandingHeader: {
    paddingBottom: '0px',
    marginLeft: 'auto',
    marginRight: '0px',
  },
  brandingBox: {
    // width: '100%',
    display: 'flex',
    flexDirection: 'row',
    textAlign: 'center',
  },
  brandingLogo: {
    paddingRight: '0px',
  },
  brandingTitle: {
    fontFamily: 'Helvetica-Bold',
    fontWeight: 'heavy',
    textAlign: 'left',
    fontSize: '20px',
    color: '#222',
    paddingRight: '3px',
    paddingTop: '4px',
    paddingBottom: '6px',
  },
})
