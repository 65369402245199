import { Grid, Breadcrumbs } from '@enterprise-ui/canvas-ui-react'
import { useNavigate } from 'react-router-dom'

export const BreadCrumbActual = ({ actualId }) => {
  const navigate = useNavigate()

  return (
    <Grid.Item justify-content="center" className="hc-pb-none">
      <Breadcrumbs className="hc-ml-none hc-pb-none">
        <Breadcrumbs.Item onClick={() => navigate('/actual')}>
          Actuals
        </Breadcrumbs.Item>
        <Breadcrumbs.Item> {actualId} </Breadcrumbs.Item>
      </Breadcrumbs>
    </Grid.Item>
  )
}
