import { useEffect, useState, useRef } from 'react'
import { GridItem, Button } from '@enterprise-ui/canvas-ui-react'
import { CSVLink } from 'react-csv'
import { DownloadIcon, EnterpriseIcon } from '@enterprise-ui/icons'
import { useGlobalForm } from '../../Context/GlobalFormStateContext'
import { editButtons } from '../../Common/CamsDivComponent'
import { useAuth } from '@praxis/component-auth'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { ActualSettlementReportToPDF } from '../PDFExports/ActualSettlementReportToPDF'

const DownloadActualSettlementReport = () => {
  const [downloadData, setDownloadData] = useState([])
  const { session } = useAuth()
  const csvLink = useRef(null)
  // eslint-disable-next-line
  const [state, dispatch] = useGlobalForm()

  useEffect(() => {
    if (downloadData.length > 0) {
      setTimeout(() => {
        csvLink?.current?.link.click()
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadData])

  const formatActualData = async () => {
    let formattedData = []

    // Add headers for Header Details
    formattedData.push([
      'Fiscal Year',
      'Contract No',
      'Location Id',
      'Location Name',
      'Vendor #',
      'Vendor Name',
      'Total Submitted Expense $',
      'Target Share - Annual $',
      'Tax Rate %',
      'Tax Amount $',
      'Target Share Annual - Incl. Taxes $',
      'Prior Payment Amount $',
      'Prior Tax Payment Amount $',
      'Final Due Amount $',
    ])

    // Add data for Header Details
    formattedData.push([
      state.actualData?.year,
      state.actualData?.contract_no,
      state.actualData?.location_id,
      state.actualData?.loc_name,
      state.actualData?.vendor_no,
      state.actualData?.vendor_name,
      state.actualData?.total_amount,
      state.actualData?.tgt_annual_amount,
      state.actualData?.tax_rate,
      state.actualData?.tax_amount,
      state.actualData?.total_due_amount,
      state.actualData?.prior_payment_amount,
      state.actualData?.prior_tax_payment_amount,
      state.actualData?.final_due_amount,
    ])

    // Add empty row
    formattedData.push([])

    // Add headers for Expense Details
    formattedData.push([
      'Expense Type Name',
      'Expense Type',
      'Total Expense Amount $',
      'PRS %',
      'PRS Amount $',
      'Admin Fee %',
      'Admin Amount $',
      'Annual Amount $',
    ])

    for (let i = 0; i < state.actualData.actual_expenses.length; i++) {
      formattedData.push([
        state.actualData?.actual_expenses[i].expense_type_name,
        state.actualData?.actual_expenses[i].expense_type,
        state.actualData?.actual_expenses[i].total_expense_amount,
        state.actualData?.actual_expenses[i].prs,
        state.actualData?.actual_expenses[i].prs_amount,
        state.actualData?.actual_expenses[i].admin_fee,
        state.actualData?.actual_expenses[i].admin_amount,
        state.actualData?.actual_expenses[i].annual_amount,
      ])
    }

    // Add Summary Data
    formattedData.push([
      '',
      'TOTAL',
      state.grandTotalExpenseAmount,
      '',
      state.totalPrsAmount,
      '',
      state.totalAdminAmount,
      state.totalAnnualAmount,
    ])
    setDownloadData(formattedData)
  }

  return (
    <>
      <GridItem>
        {/* Export to CSV option */}
        <Button
          type="primary"
          css={editButtons}
          className="hc-ml-xs"
          onClick={async () => {
            formatActualData()
          }}
        >
          <EnterpriseIcon icon={DownloadIcon} /> &nbsp;Export to CSV
          {/* <FontAwesomeIcon className="hc-ml-normal" icon={faCaretDown} /> */}
        </Button>
        {/* Export to PDF option */}
        <PDFDownloadLink
          document={
            <ActualSettlementReportToPDF
              title={'CAM Payable Actual Report'}
              data={state}
              session={session}
            />
          }
          fileName={`${state.actualData?.location_id}-${state.actualData?.year}-${state.actualData?.contract_no}-payable-actual.pdf`}
        >
          <Button type="primary" css={editButtons} className="hc-ml-xs">
            <EnterpriseIcon icon={DownloadIcon} /> &nbsp;Export to PDF
          </Button>
        </PDFDownloadLink>
      </GridItem>
      {downloadData.length > 0 && (
        <CSVLink
          data={downloadData}
          filename={`${state.actualData?.location_id}-${state.actualData?.year}-${state.actualData?.contract_no}-payable-actual.csv`}
          className="hidden"
          ref={csvLink}
          target="_blank"
        />
      )}
    </>
  )
}

export default DownloadActualSettlementReport
