import React from 'react'
import { Grid, Input, Heading } from '@enterprise-ui/canvas-ui-react'

export const Section = (props) => {
  const itemContent = []
  if (props?.children?.length === undefined) {
    itemContent.push(
      React.cloneElement(props?.children, {
        size: props.labelGridSize,
        detail: props.detail,
        summarysize: props.valueGridSize,
      })
    )
  }
  for (var i = 0; i < props.children?.length; i++) {
    if (props.children[i].type?.displayName === 'SectionItem') {
      itemContent.push(
        React.cloneElement(props.children[i], {
          size: props.labelGridSize,
          detail: props.detail,
          summarysize: props.valueGridSize,
        })
      )
    } else {
      if (props?.children[i]?.props?.children?.length > 0) {
        for (var j = 0; j < props?.children[i]?.props?.children?.length; j++) {
          itemContent.push(
            React.cloneElement(props?.children[i]?.props?.children[j], {
              size: props.labelGridSize,
              detail: props.detail,
              summarysize: props.valueGridSize,
            })
          )
        }
      }
    }
  }

  const sectionClass =
    props.side === 'left'
      ? 'hc-pl-expanded hc-pb-expanded hc-pr-none hc-pt-expanded'
      : 'hc-pl-expanded hc-pb-normal hc-pr-none hc-pt-expanded'

  return (
    <Grid.Container className={sectionClass}>
      <Heading className="hc-pb-expanded" size={6}>
        {props.heading}
      </Heading>
      <Grid.Container className="hc-pl-sm hc-mv-min">
        <Grid.Container>{itemContent}</Grid.Container>
      </Grid.Container>
    </Grid.Container>
  )
}

export const SectionItem = (props) => {
  const finalClass = 'hc-pt-normal hc-pb-normal'
  return (
    <Grid.Container>
      <Grid.Item md={props.size} sm={6} className={finalClass}>
        <Input.Label className="hc-clr-grey01 hc-fs-sm hc-mb-md  hc-pl-normal">
          {props.label}:
        </Input.Label>
      </Grid.Item>
      <Grid.Item md={props.summarysize} sm={6} className={finalClass}>
        <Input.Label className="hc-clr-contrast hc-fs-sm hc-mb-md">
          {props.children !== undefined &&
          props.children !== null &&
          props.children !== 'NA' &&
          props.children !== '' &&
          Object(props?.children).props?.date !== null
            ? props.children
            : 'None'}
        </Input.Label>
      </Grid.Item>
    </Grid.Container>
  )
}
SectionItem.displayName = 'SectionItem'

export default Section
