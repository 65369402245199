import { Security } from '../../../globalConfig/common/ConstantData'

const dateFilters = [
  {
    id: '7d',
    label: 'Last 7 days',
    value: {
      from: 'now-7d',
      to: 'now',
    },
  },
  {
    id: '30d',
    label: 'Last 30 Days',
    value: {
      from: 'now-30d',
      to: 'now',
    },
  },
  {
    id: '7-14d',
    label: 'Between 7 to 14 Days',
    value: {
      from: 'now-14d',
      to: 'now-7d',
    },
  },
]

export const budgetInitialValue = {
  searchFilters: [],
  loading: false,
  // eslint-disable-next-line
  sideFilters: [
    {
      id: 'fiscal_year',
      displayName: 'Year',
      security: Security.BOTH,
      type: 'search_group',
      startExpanded: true,
      searchFields: [
        {
          field: 'fiscal_year',
          placeHolder: '',
          label: 'Year',
          values: [],
          suggestField: 'fiscal_year.suggest',
        },
      ],
    },
    {
      id: 'status',
      displayName: 'Status',
      security: Security.INTERNAL,
      values: [
        {
          id: 'Not Started',
          value: 'Not Started',
          label: 'Not Started',
          isChecked: false,
        },
        {
          id: 'In Progress',
          value: 'In Progress',
          label: 'In Progress',
          isChecked: false,
        },
        {
          id: 'More Info Required',
          value: 'More Info Required',
          label: 'More Info Required',
          isChecked: false,
        },
        {
          id: 'Submitted for Approval',
          value: 'Submitted for Approval',
          label: 'Submitted for Approval',
          isChecked: false,
        },
        {
          id: 'Approved',
          value: 'Approved',
          label: 'Approved',
          isChecked: false,
        },
        {
          id: 'Approved By SiteOps',
          value: 'Approved By SiteOps',
          label: 'Approved By SiteOps',
          isChecked: false,
        },
        {
          id: 'Denied',
          value: 'Denied',
          label: 'Denied',
          isChecked: false,
        },
        {
          id: 'Reopened',
          value: 'Reopened',
          label: 'Reopened',
          isChecked: false,
        },
      ],
      type: 'checkbox',
    },
    {
      id: 'workflow',
      displayName: 'Workflow',
      security: Security.INTERNAL,
      values: [
        {
          id: 'PAA',
          value: 'PAA',
          label: 'PAA',
          isChecked: false,
        },
        {
          id: 'PAM',
          value: 'PAM',
          label: 'PAM',
          isChecked: false,
        },
        {
          id: 'SITEOPS',
          value: 'SITEOPS',
          label: 'SITEOPS',
          isChecked: false,
        },
        {
          id: 'VENDOR',
          value: 'VENDOR',
          label: 'VENDOR',
          isChecked: false,
        },
      ],
      type: 'checkbox',
    },
    {
      id: 'createDate',
      displayName: 'Create Date',
      security: Security.BOTH,
      values: dateFilters,
      type: 'date',
      startExpanded: false,
    },
    {
      id: 'updateDate',
      displayName: 'Updated Date',
      security: Security.BOTH,
      values: dateFilters,
      type: 'date',
      startExpanded: false,
    },
    {
      id: 'assigned_user_id',
      displayName: 'Team Member',
      security: Security.INTERNAL,
      values: [],
      type: 'checkbox',
    },
    {
      id: 'vendorNo',
      displayName: 'Vendor No',
      security: Security.INTERNAL,
      type: 'search_group',
      startExpanded: false,
      searchFields: [
        {
          field: 'vendorNo',
          placeHolder: '',
          label: 'Vendor No',
          values: [],
          suggestField: 'vendorNo.suggest',
        },
      ],
    },
    {
      id: 'budgetId',
      displayName: 'BUDGET ID',
      security: Security.INTERNAL,
      type: 'search_group',
      startExpanded: false,
      searchFields: [
        {
          field: 'budgetId',
          placeHolder: '',
          label: 'Budget #',
          values: [],
        },
      ],
    },
    {
      id: 'locationId',
      displayName: 'Location',
      security: Security.BOTH,
      type: 'search_group',
      startExpanded: false,
      searchFields: [
        {
          field: 'locationId',
          placeHolder: '',
          label: 'Location #',
          values: [],
          suggestField: 'location_id.suggest',
        },
      ],
    },
    {
      id: 'contractNo',
      displayName: 'Contract No',
      security: Security.INTERNAL,
      type: 'search_group',
      startExpanded: false,
      searchFields: [
        {
          field: 'contractNo',
          placeHolder: '',
          label: 'Contract #',
          values: [],
          suggestField: 'contract_no.suggest',
        },
      ],
    },
    ,
    {
      id: 'contractName',
      displayName: 'Contract Name',
      security: Security.BOTH,
      type: 'search_group',
      startExpanded: false,
      searchFields: [
        {
          field: 'contractName',
          placeHolder: '',
          label: 'Contract Name',
          values: [],
          suggestField: 'contract_name.suggest',
        },
      ],
    },
  ],
}

export const actualInitialValue = {
  searchFilters: [],
  loading: false,
  // eslint-disable-next-line
  sideFilters: [
    {
      id: 'fiscal_year',
      displayName: 'Year',
      security: Security.BOTH,
      type: 'search_group',
      startExpanded: true,
      searchFields: [
        {
          field: 'fiscal_year',
          placeHolder: '',
          label: 'Year',
          values: [],
          suggestField: 'fiscal_year.suggest',
        },
      ],
    },
    {
      id: 'status',
      displayName: 'Status',
      security: Security.INTERNAL,
      values: [
        {
          id: 'Not Started',
          value: 'Not Started',
          label: 'Not Started',
          isChecked: false,
        },
        {
          id: 'In Progress',
          value: 'In Progress',
          label: 'In Progress',
          isChecked: false,
        },
        {
          id: 'More Info Required',
          value: 'More Info Required',
          label: 'More Info Required',
          isChecked: false,
        },
        {
          id: 'Submitted for Approval',
          value: 'Submitted for Approval',
          label: 'Submitted for Approval',
          isChecked: false,
        },
        {
          id: 'Approved',
          value: 'Approved',
          label: 'Approved',
          isChecked: false,
        },
        {
          id: 'Approved By SiteOps',
          value: 'Approved By SiteOps',
          label: 'Approved By SiteOps',
          isChecked: false,
        },
        {
          id: 'Denied',
          value: 'Denied',
          label: 'Denied',
          isChecked: false,
        },
        {
          id: 'Reopened',
          value: 'Reopened',
          label: 'Reopened',
          isChecked: false,
        },
      ],
      type: 'checkbox',
    },
    {
      id: 'workflow',
      displayName: 'Workflow',
      security: Security.INTERNAL,
      values: [
        {
          id: 'PAA',
          value: 'PAA',
          label: 'PAA',
          isChecked: false,
        },
        {
          id: 'PAM',
          value: 'PAM',
          label: 'PAM',
          isChecked: false,
        },
        {
          id: 'SITEOPS',
          value: 'SITEOPS',
          label: 'SITEOPS',
          isChecked: false,
        },
        {
          id: 'VENDOR',
          value: 'VENDOR',
          label: 'VENDOR',
          isChecked: false,
        },
      ],
      type: 'checkbox',
    },
    {
      id: 'createDate',
      displayName: 'Create Date',
      security: Security.BOTH,
      values: dateFilters,
      type: 'date',
      startExpanded: false,
    },
    {
      id: 'updateDate',
      displayName: 'Updated Date',
      security: Security.BOTH,
      values: dateFilters,
      type: 'date',
      startExpanded: false,
    },
    {
      id: 'assigned_user_id',
      displayName: 'Team Member',
      security: Security.INTERNAL,
      values: [],
      type: 'checkbox',
    },
    {
      id: 'vendorNo',
      displayName: 'Vendor No',
      security: Security.INTERNAL,
      type: 'search_group',
      startExpanded: false,
      searchFields: [
        {
          field: 'vendorNo',
          placeHolder: '',
          label: 'Vendor No',
          values: [],
          suggestField: 'vendorNo.suggest',
        },
      ],
    },
    {
      id: 'actualId',
      displayName: 'ACTUAL ID',
      security: Security.INTERNAL,
      type: 'search_group',
      startExpanded: false,
      searchFields: [
        {
          field: 'actualId',
          placeHolder: '',
          label: 'Actual #',
          values: [],
        },
      ],
    },
    {
      id: 'locationId',
      displayName: 'Location',
      security: Security.BOTH,
      type: 'search_group',
      startExpanded: false,
      searchFields: [
        {
          field: 'locationId',
          placeHolder: '',
          label: 'Location #',
          values: [],
          suggestField: 'location_id.suggest',
        },
      ],
    },
    {
      id: 'contractNo',
      displayName: 'Contract No',
      security: Security.INTERNAL,
      type: 'search_group',
      startExpanded: false,
      searchFields: [
        {
          field: 'contractNo',
          placeHolder: '',
          label: 'Contract #',
          values: [],
          suggestField: 'contract_no.suggest',
        },
      ],
    },
    ,
    {
      id: 'contractName',
      displayName: 'Contract Name',
      security: Security.BOTH,
      type: 'search_group',
      startExpanded: false,
      searchFields: [
        {
          field: 'contractName',
          placeHolder: '',
          label: 'Contract Name',
          values: [],
          suggestField: 'contract_name.suggest',
        },
      ],
    },
  ],
}
