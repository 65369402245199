import React, { useEffect } from 'react'
import { SearchStateContext, SearchDispatchContext } from './SearchContext'
import { searchReducer } from './SearchReducer'
import { budgetInitialValue, actualInitialValue } from './SearchInitialValues'
import { useImmerReducer } from 'use-immer'
import { useCommonRequest } from '../../../globalComponents/hooks/useCommonRequest'

const SearchProvider = ({ type, children }) => {
  const initialValue =
    type === 'actual' ? actualInitialValue : budgetInitialValue
  const [state, dispatch] = useImmerReducer(searchReducer, initialValue)

  const { getAllAssigneeForAdGroup } = useCommonRequest()

  useEffect(() => {
    getAllAssigneeForAdGroup().then((response) => {
      setDefaultFilters(dispatch, response, 'assigned_user_id')
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <SearchStateContext.Provider value={state}>
      <SearchDispatchContext.Provider value={dispatch}>
        {children}
      </SearchDispatchContext.Provider>
    </SearchStateContext.Provider>
  )
}

function useSearchState() {
  const context = React.useContext(SearchStateContext)
  if (context === undefined) {
    throw new Error('useCountState must be used within a SearchProvider')
  }
  return context
}
function useSearchDispatch() {
  const context = React.useContext(SearchDispatchContext)
  if (context === undefined) {
    throw new Error('useCountDispatch must be used within a SearchProvider')
  }
  return context
}

function useCaseSearch() {
  return [useSearchState(), useSearchDispatch()]
}

export { SearchProvider, useCaseSearch, useSearchState, useSearchDispatch }

function setDefaultFilters(dispatch, data, field) {
  const enrichedData = data.map((val) => {
    return {
      id: val.id,
      label: val.label,
      value: val.value,
      isChecked: false,
    }
  })
  let obj = {}
  obj[field] = enrichedData
  dispatch({
    type: 'SET_DEFAULT_SIDE_FILTERS',
    payload: obj,
  })
}
