import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'

export const FilterSearch = ({ id, label, onUpdate, placeHolder }) => {
  const handleSearchSuggestion = (id, value) => {
    if (value) {
      onUpdate(id, value)
    }
  }

  return (
    <div style={{ zIndex: 100 }}>
      <Autocomplete
        id={id}
        options={[]}
        label={label}
        onUpdate={(id, value) => {
          if (value?.isNew === true) {
            let option = {
              id: value.value,
              label: value.label,
              value: value.value,
            }
            handleSearchSuggestion(id, option)
          } else {
            handleSearchSuggestion(id, value)
          }
        }}
        placeholder={placeHolder}
        showSearchIcon
        // retainSearchText
        allowCustomInputValue
      />
    </div>
  )
}
