import { Routes, Route, useLocation } from 'react-router-dom'
import { ErrorBoundary } from '@praxis/component-logging'
import { ErrorContent } from '../globalComponents/ErrorContent'
import { useEnv } from '@praxis/component-runtime-env'
import { ProtectedElement } from '@praxis/component-auth'
import { ContractMetadata } from '../views/Admin/ContractMetadata/ContractMetadata'
import { ExpensePool } from '../views/Admin/ExpensePool/ExpensePool'
import { CreateBudget } from '../views/Budget/CreateBudget/CreateBudget'
import { CreateActual } from '../views/Actual/CreateActual/CreateActual'
import { BudgetView } from '../views/Budget/BudgetView/index'
import { ActualView } from '../views/Actual/ActualView/index'
import Budget from '../views/Search/Budget'
import Actual from '../views/Search/Actual'

export const MainRouter = () => {
  const location = useLocation()
  const { auth } = useEnv()
  return (
    <>
      <ErrorBoundary key={location.pathname} FallbackComponent={ErrorContent}>
        <Routes>
          <Route
            exact
            path="/contractMetadata"
            element={
              <ProtectedElement
                allowed={[
                  auth.analystRole,
                  auth.managerRole,
                  auth.siteOpsRole,
                  auth.adminRole,
                ].flat()}
              >
                <ContractMetadata />
              </ProtectedElement>
            }
          />
          <Route
            exact
            path="/expensePool"
            element={
              <ProtectedElement
                allowed={[
                  auth.analystRole,
                  auth.managerRole,
                  auth.siteOpsRole,
                  auth.adminRole,
                ].flat()}
              >
                <ExpensePool />
              </ProtectedElement>
            }
          />
          <Route
            exact
            path="/"
            element={
              <ProtectedElement
                allowed={[
                  auth.analystRole,
                  auth.managerRole,
                  auth.siteOpsRole,
                  auth.adminRole,
                  auth.vendorRole,
                ].flat()}
              >
                <Budget />
              </ProtectedElement>
            }
          />
          <Route
            exact
            path="/actual"
            element={
              <ProtectedElement
                allowed={[
                  auth.analystRole,
                  auth.managerRole,
                  auth.siteOpsRole,
                  auth.adminRole,
                  auth.vendorRole,
                ].flat()}
              >
                <Actual />
              </ProtectedElement>
            }
          />
          <Route
            exact
            path="/budget/create"
            element={
              <ProtectedElement
                allowed={[
                  auth.analystRole,
                  auth.managerRole,
                  auth.siteOpsRole,
                  auth.adminRole,
                  auth.vendorRole,
                ].flat()}
              >
                <CreateBudget />
              </ProtectedElement>
            }
          />
          <Route
            exact
            path="/actual/create"
            element={
              <ProtectedElement
                allowed={[
                  auth.analystRole,
                  auth.managerRole,
                  auth.siteOpsRole,
                  auth.adminRole,
                  auth.vendorRole,
                ].flat()}
              >
                <CreateActual />
              </ProtectedElement>
            }
          />
          <Route
            exact
            path="/budget/:id"
            element={
              <ProtectedElement
                allowed={[
                  auth.analystRole,
                  auth.managerRole,
                  auth.siteOpsRole,
                  auth.adminRole,
                  auth.vendorRole,
                ].flat()}
              >
                <BudgetView />
              </ProtectedElement>
            }
          />
          <Route
            exact
            path="/actual/:id"
            element={
              <ProtectedElement
                allowed={[
                  auth.analystRole,
                  auth.managerRole,
                  auth.siteOpsRole,
                  auth.adminRole,
                  auth.vendorRole,
                ].flat()}
              >
                <ActualView />
              </ProtectedElement>
            }
          />
        </Routes>
      </ErrorBoundary>
    </>
  )
}
