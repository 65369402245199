import { BottomBorderDiv } from '../CamsDivComponent'
import Section, { SectionItem } from '../Section'

export const ViewVendorDetails = ({ vendorDetails }) => {
  return (
    <BottomBorderDiv>
      <Section
        labelGridSize={3}
        detail={false}
        heading={'Vendor Details'}
        expandable
      >
        <SectionItem label={'Vendor ID'}>
          {vendorDetails?.vendor_no}
        </SectionItem>
        <SectionItem label={'Vendor Name'}>
          {vendorDetails?.vendor_name}
        </SectionItem>
        <SectionItem label="Contact">
          {vendorDetails?.submitted_by_name
            ? vendorDetails?.submitted_by_name
            : 'None'}
        </SectionItem>
        <SectionItem label="Email">
          {vendorDetails?.submitted_by_email
            ? vendorDetails?.submitted_by_email
            : 'None'}
        </SectionItem>
      </Section>
    </BottomBorderDiv>
  )
}
