import { BottomBorderDiv } from '../CamsDivComponent'
import Section, { SectionItem } from '../Section'

export const ViewContractDetails = ({ contractDetails }) => {
  return (
    <BottomBorderDiv>
      <Section
        labelGridSize={3}
        detail={false}
        heading={'Contract Details'}
        side={'left'}
        expandable
      >
        <SectionItem label={'Contract No'}>
          {contractDetails?.contract_no}
        </SectionItem>
        <SectionItem label={'Contract Name'}>
          {contractDetails?.contract_name}
        </SectionItem>
      </Section>
    </BottomBorderDiv>
  )
}
