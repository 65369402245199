import {
  Grid,
  Layout,
  Button,
  Heading,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { useState, useMemo, useEffect, useContext } from 'react'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
// import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-community/styles/ag-theme-balham.css'
import { AddContractMetadata } from '../ContractMetadata/AddContractMetadata'
import { EditContractMetadata } from '../ContractMetadata/EditContractMetadata'
import { EmailNotification } from './EmailNotification'
import { LoadingSpinner } from '../../../globalComponents/LoadingSpinner'
import EnterpriseIcon, { PencilIcon } from '@enterprise-ui/icons'
import { AdminContext } from '../../../globalConfig/AdminContext'
import { contractStatusOptions } from '../../../globalConfig/common/ConstantData'
import { pageSizeOptions } from '../../../globalConfig/common/ConstantData'

export const ContractMetadata = () => {
  const context = useContext(AdminContext)
  const [addContractMetadata, setAddContractMetadata] = useState(false)
  const [editContractMetadata, setEditContractMetadata] = useState(false)
  const [editableMetadata, setEditableMetadata] = useState({})
  const [selectedRowCount, setSelectedRowCount] = useState(0)
  const [pageSize, setPageSize] = useState(10)

  const [rowData] = [context.contractMetadata]

  useEffect(() => {
    context.fetchContractMetadata()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAddContractMetadata = () => {
    setAddContractMetadata(true)
  }

  const isEditActionDisabled = () => {
    return selectedRowCount !== 0
  }

  const isRowSelectable = useMemo(() => {
    return (params) => {
      return !!params.data && params.data.contract_status === 'A'
    }
  }, [])

  const onSelectionChanged = (event) => {
    setSelectedRowCount(event.api.getSelectedRows().length)
  }

  const statusFormatter = (params) => {
    var filteredStatus = contractStatusOptions.filter(function (e) {
      return e.value === params.data.contract_status
    })
    return filteredStatus[0].label
  }

  const regionFormatter = (params) => {
    if (params.data.region === 0) {
      return ' '
    } else {
      return params.data.region
    }
  }

  const [columnDefs] = useState([
    {
      headerName: 'Edit',
      pinned: 'left',
      cellStyle: {
        textAlign: 'center',
      },
      maxWidth: 60,
      filter: false,
      cellRenderer: (props) => {
        return (
          <Button
            iconOnly
            type="ghost"
            aria-label="Edit Icon"
            disabled={isEditActionDisabled()}
            onClick={() => {
              setEditableMetadata(props.data)
              setEditContractMetadata(true)
            }}
          >
            <EnterpriseIcon icon={PencilIcon} />
          </Button>
        )
      },
    },
    {
      headerName: 'CAM Payable / Rebill',
      field: 'contract_metadata_type',
      width: 150,
      // headerCheckboxSelection: true,
      // checkboxSelection: true,
      showDisabledCheckboxes: true,
    },

    { headerName: 'Contract#', field: 'contract_no', width: 125 },
    {
      headerName: 'Contract Start Date',
      field: 'contract_start_date',
      width: 130,
    },
    { headerName: 'Contract End Date', field: 'contract_end_date', width: 130 },
    {
      headerName: 'Contract Status',
      field: 'contract_status',
      width: 100,
      filterParams: {
        defaultOption: 'startsWith',
      },
      valueGetter: statusFormatter,
    },
    { headerName: 'Contract Name', field: 'contract_name', width: 150 },
    { field: 'region', width: 120, valueGetter: regionFormatter },
    { headerName: 'PRS', field: 'prs', width: 100 },
    { headerName: 'Site Type', field: 'site_type', width: 150 },
    { headerName: 'Location', field: 'location_id', width: 110 },
    { headerName: 'Store Name', field: 'loc_name', width: 150 },
    { headerName: 'Street Address', field: 'loc_address', width: 150 },
    { headerName: 'City', field: 'loc_city', width: 100 },
    { headerName: 'State', field: 'loc_state', width: 80 },
    { headerName: 'Vendor#', field: 'vendor_no', width: 120 },
    { headerName: 'Vendor Name', field: 'vendor_name', width: 150 },
    { headerName: 'Vendor Email Id', field: 'email_id', width: 150 },
    {
      headerName: 'Operator / Landlord',
      field: 'operator_or_landlord',
      width: 120,
    },
    {
      headerName: 'Budget Requirement',
      field: 'budget_requirement',
      width: 130,
    },
    { headerName: 'Recon Requirement', field: 'recon_requirement', width: 130 },
  ])

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      floatingFilter: true,
      filter: true,
      wrapText: true,
      autoHeight: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
    }),
    []
  )

  return (
    <>
      <Layout.SectionHeader>
        <div className="hc-pa-dense hc-pb-none">
          <Grid.Container justify="space-between">
            <Grid.Item>
              <Heading size={4}>Manage Contract Metadata</Heading>
            </Grid.Item>
            <Grid.Item>
              <Grid.Container>
                <Grid.Item>
                  <Button
                    type="secondary"
                    onClick={handleAddContractMetadata}
                    disabled={isEditActionDisabled()}
                  >
                    Add Contract Metadata
                  </Button>
                </Grid.Item>
              </Grid.Container>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Layout.SectionHeader>
      <Layout.Body
        data-testid="layoutWithRightRailMainContent"
        className="hc-pa-md"
        includeRail
      >
        {!context.loadingContractMetadata ? (
          <>
            <div>
              <Grid.Container>
                <Grid.Item className="hc-pt-md hc-pr-none">
                  <p>Page Size: </p>
                </Grid.Item>
                <Grid.Item xs={1} className="hc-pb-md hc-pl-sm hc-fs-xs">
                  <Input.Select
                    style={{ height: '25px' }}
                    id={'pageSize'}
                    label={'Page Size'}
                    defaultValue={10}
                    onUpdate={(id, value) => {
                      setPageSize(value)
                    }}
                    options={pageSizeOptions}
                  />
                </Grid.Item>
              </Grid.Container>
            </div>
            <Grid.Container justify="center">
              <div
                className="ag-theme-balham"
                style={{ height: '100%', width: '100%' }}
              >
                <AgGridReact
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  // rowSelection="multiple"
                  suppressRowClickSelection={true}
                  isRowSelectable={isRowSelectable}
                  onSelectionChanged={onSelectionChanged}
                  pagination={true}
                  paginationPageSize={pageSize}
                  rowHeight="25"
                  domLayout="autoHeight"
                ></AgGridReact>
              </div>
            </Grid.Container>
          </>
        ) : (
          <LoadingSpinner />
        )}
        <AddContractMetadata
          addContractMetadataVisible={addContractMetadata}
          setAddContractMetadataVisible={setAddContractMetadata}
        ></AddContractMetadata>
        <EditContractMetadata
          editContractMetadataVisible={editContractMetadata}
          setEditContractMetadataVisible={setEditContractMetadata}
          data={editableMetadata}
        ></EditContractMetadata>

        {selectedRowCount > 0 && (
          <div className="hc-mt-lg">
            <Grid.Container justify="space-between">
              <Grid.Item>
                <Heading size={5}>Email Notification</Heading>
              </Grid.Item>
            </Grid.Container>
            <EmailNotification />
          </div>
        )}
      </Layout.Body>
    </>
  )
}
