import {
  Button,
  Grid,
  Modal,
  Spinner,
  Timeline,
} from '@enterprise-ui/canvas-ui-react'
import React, { useContext } from 'react'
import { useGlobalForm } from '../../Context/GlobalFormStateContext'
import { SET_FIELD } from '../../../globalConfig/common/ConstantData'
import { useNavigate } from 'react-router'
import { AttachmentContext } from '../../Context/AttachmentContext'
import { useAttachmentRequest } from '../../hooks/useAttachmentRequest'

export const SaveTimelineActualModal = ({
  redirectUrl,
  requestId,
  vendorNo,
  docType,
}) => {
  console.log('Redirect URL=', redirectUrl)
  console.log('Attachment requestId = ', requestId)
  console.log('vendorNo', vendorNo)
  console.log('doc type ', docType)
  const [state, dispatch] = useGlobalForm()
  const context = useContext(AttachmentContext)
  const { retryAttachment } = useAttachmentRequest()
  const navigate = useNavigate()

  return (
    <Grid.Container className="hc-pa-normal">
      <Grid.Item className="hc-pa-normal">
        <Modal
          headingText={'Actual Creation Status'}
          isVisible={state.creatingActual}
          onRefuse={() => {
            dispatch({
              type: SET_FIELD,
              payload: [{ id: 'creatingActual', value: false }],
            })
          }}
        >
          <Timeline className="hc-pa-normal hc-ma-normal">
            {state.submitStatuses?.map((statusDetails, index) => (
              <Timeline.Item
                key={index}
                type={!statusDetails.status ? 'error' : 'success'}
              >
                {statusDetails.message.search(
                  'Actual Created with Actual Number'
                ) !== -1 ? (
                  <React.Fragment>
                    <p>Actual created with Actual Number: </p>
                    <p className="hc-clr-success">
                      {statusDetails.message.split(':')[1]}
                    </p>
                  </React.Fragment>
                ) : (
                  <p>{statusDetails.message}</p>
                )}
              </Timeline.Item>
            ))}
            {context.uploadedAttachments?.length !== 0 && (
              <>
                <Timeline.Flag>Uploading Attachments</Timeline.Flag>
                {context.uploadedAttachments.map((file, id) => (
                  <Timeline.Item key={id} type={file.status ?? 'default'}>
                    {!file.uploading && (
                      <p>
                        {file.name} &nbsp;
                        {file.status === 'error' &&
                        file.errorMessage === null ? (
                          <span
                            className="hc-clr-error"
                            style={{
                              textDecoration: 'underline',
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              retryAttachment(
                                requestId,
                                vendorNo,
                                file.name,
                                docType
                              )
                            }}
                          >
                            retry
                          </span>
                        ) : (
                          ''
                        )}
                        {file.status === 'error' &&
                        file.errorMessage !== null ? (
                          <span className="hc-clr-error">
                            {file.errorMessage}
                          </span>
                        ) : (
                          ''
                        )}
                      </p>
                    )}
                    {file.uploading && (
                      <>
                        <Spinner size="dense" /> &nbsp; Uploading {file.name}
                      </>
                    )}
                  </Timeline.Item>
                ))}
              </>
            )}
          </Timeline>{' '}
          <Grid.Container
            className="hc-pa-normal"
            spacing="dense"
            justify="flex-end"
          >
            <Grid.Item>
              <Button
                type="secondary"
                className="hc-pa-normal hc-ma-normal"
                disabled={state.isSubmit}
                onClick={async () => {
                  dispatch({
                    type: SET_FIELD,
                    payload: [
                      { id: 'creatingActual', value: false },
                      {
                        id: 'isActualChanged',
                        value: false,
                      },
                    ],
                  })
                  context.setUploadedAttachments([])
                  navigate(redirectUrl)
                }}
              >
                OK
              </Button>
              {state.isSubmit && <Spinner size={'dense'} />}
            </Grid.Item>
          </Grid.Container>
        </Modal>
      </Grid.Item>
    </Grid.Container>
  )
}
export default SaveTimelineActualModal
