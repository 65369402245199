import _ from 'lodash'
import { SET_DEFAULT_SIDE_FILTERS, UPDATE_FILTER } from './ActionTypes'

export function searchReducer(draft, action) {
  switch (action.type) {
    case SET_DEFAULT_SIDE_FILTERS: {
      draft.sideFilters.forEach((filter) => {
        if (action.payload[filter.id] !== undefined) {
          filter.values = action.payload[filter.id]
        }
      })
      return
    }

    case UPDATE_FILTER: {
      // checkbox
      draft.sideFilters
        .filter((filter) => filter.type === 'checkbox')
        .forEach((filter) => {
          const selectedValues = action.payload[filter.id]
          filter.values?.forEach((value) => {
            value.isChecked = _.includes(selectedValues, value.id)
          })
        })

      //date range
      draft.sideFilters
        .filter((filter) => filter.type === 'date')
        .forEach((filter) => {
          filter.active = action.payload[filter.id] ?? null
        })

      //radio
      draft.sideFilters
        .filter((filter) => filter.type === 'radio')
        .forEach((filter) => {
          const selectedValue = action.payload[filter.id]
          filter.values?.forEach((value) => {
            value.isChecked = selectedValue === value.id
          })
        })

      //search group
      draft.sideFilters
        .filter((filter) => filter.type === 'search_group')
        .forEach((filter) => {
          const searchFilters = action.payload[filter.id]
          const searchFilterKeys = Object.keys(searchFilters ?? {})
          filter.searchFields?.forEach((field) => {
            if (_.includes(searchFilterKeys, field.field)) {
              field.values = searchFilters[field.field]?.map((value) => ({
                id: value,
                value: value,
                label: value,
              }))
            } else {
              field.values = []
            }
          })
        })

      // main search
      if (action.payload['search'] !== undefined) {
        const searchFilters = []
        action.payload['search'].forEach((value) => {
          searchFilters.push({
            id: value,
            value: value,
            label: value,
          })
        })
        draft.searchFilters = _.uniqBy(searchFilters, 'id')
      } else {
        draft.searchFilters = []
      }

      return
    }
    default:
      return
  }
}
