import { Chip } from '@enterprise-ui/canvas-ui-react'
import Section, { SectionItem } from '../Section'
import { BudgetStatus } from '../../../globalConfig/common/ConstantData'

export const ViewWorkflowDetails = ({
  workflowDetails,
  attachmentAvailable,
}) => {
  let actualStatus = workflowDetails?.status
  let color = ''
  if (workflowDetails?.status === BudgetStatus.NOT_STARTED) {
    color = 'default'
  } else if (
    workflowDetails?.status === BudgetStatus.IN_PROGRESS ||
    workflowDetails?.status === BudgetStatus.MORE_INFO_REQUIRED ||
    workflowDetails?.status === BudgetStatus.REOPENED ||
    workflowDetails?.status === BudgetStatus.SUBMITTED_FOR_APPROVAL
  ) {
    color = 'alert'
  } else if (workflowDetails?.status === BudgetStatus.DENIED) {
    color = 'error'
  } else if (
    workflowDetails?.status === BudgetStatus.APPROVED ||
    workflowDetails?.status === BudgetStatus.APPROVED_BY_SITEOPS
  ) {
    color = 'success'
  }

  return (
    <Section
      labelGridSize={4}
      detail={false}
      heading={'Workflow Details'}
      expandable
    >
      <SectionItem label={'Status'}>
        <Chip size="dense" color={color}>
          {actualStatus}
        </Chip>
      </SectionItem>
      <SectionItem label={'Attachments'}>
        <Chip size="dense">
          {attachmentAvailable ? 'Available' : 'Not Available'}
        </Chip>
      </SectionItem>
      <SectionItem label={'Workflow'}>
        {workflowDetails?.workflow ? workflowDetails?.workflow : 'None'}
      </SectionItem>
      <SectionItem label={'Assignee'}>
        {workflowDetails?.assigned_user_name}
      </SectionItem>
    </Section>
  )
}
