import { useState } from 'react'
import { Layout } from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'
import { MainRouter } from '../globalComponents/MainRouter'
import { Header } from '../globalComponents/Header'
import { SideNavigation } from '../globalComponents/SideNavigation'
import { AdminProvider } from '../globalConfig/AdminContext'
import { ToastProvider } from '@enterprise-ui/canvas-ui-react'
import axios from 'axios'
import { isSSOSessionActive } from './AuthUtils'

export const Main = () => {
  const auth = useAuth()

  const [isSideNavOpen, setIsSideNavOpen] = useState(true)
  const handleSideNavToggle = () => {
    setIsSideNavOpen(!isSideNavOpen)
  }

  axios.interceptors.request.use(function (config) {
    if (isSSOSessionActive()) {
      // add the authorization to the headers

      config.headers.common['Authorization'] =
        localStorage.getItem('access_token') || null
    } else {
      auth.login()
    }
    return config
  })

  return (
    <ToastProvider location="bottom">
      <AdminProvider>
        <Layout
          theme="blue" // configure theme here
          darkMode="false" // configure dark mode here
          data-testid="mainAuthenticatedLayout"
        >
          <Header onSideNavToggle={handleSideNavToggle} />
          <SideNavigation
            isOpen={isSideNavOpen}
            onRequestClose={handleSideNavToggle}
          />
          <MainRouter />
        </Layout>
      </AdminProvider>
    </ToastProvider>
  )
}
