import { Grid, Modal, Button, Heading } from '@enterprise-ui/canvas-ui-react'
import { Link } from 'react-router-dom'
import '../Budget/Custom.css'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import { SET_FIELD } from '../../globalConfig/common/ConstantData'

export const CancelModal = ({ headingText, heading }) => {
  const [state, dispatch] = useGlobalForm()
  return (
    <div>
      <Modal
        headingText={headingText}
        isVisible={state.isCancel}
        onRefuse={() => {
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'isCancel', value: false }],
          })
        }}
      >
        <Grid.Item xs={12} className="hc-pa-normal">
          <Grid.Container>
            <Grid.Item xs>
              <Heading size={6}>{heading}</Heading>
            </Grid.Item>
          </Grid.Container>
          <Grid.Container direction="row-reverse" spacing="dense">
            <Grid.Item>
              <Button type="secondary" as={Link} to={'/'}>
                Yes
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button
                type="secondary"
                onClick={() => {
                  dispatch({
                    type: SET_FIELD,
                    payload: [{ id: 'isCancel', value: false }],
                  })
                }}
              >
                No
              </Button>
            </Grid.Item>
          </Grid.Container>
        </Grid.Item>
      </Modal>
    </div>
  )
}
