import { useState } from 'react'
import { Button, Grid, Form, Card, Modal } from '@enterprise-ui/canvas-ui-react'
import { useGlobalForm } from '../../Context/GlobalFormStateContext'
import { SET_FIELD } from '../../../globalConfig/common/ConstantData'

export const ViewUserQueryModal = ({
  headingText,
  placeholderText,
  formik,
  userProfile,
  saveUserQuery,
}) => {
  const [state, dispatch] = useGlobalForm()
  const [addNewCommentFlag, setAddNewCommentFlag] = useState(false)
  const [newComment, setNewComment] = useState('')

  function handleAdd() {
    let newNotes = []
    newNotes.push({
      actual_id: state.actualData?.actual_id,
      user_id: userProfile?.userId,
      user_name: userProfile?.fullName,
      comments: newComment,
      comment_ts: new Date().toISOString(),
    })

    formik.setFieldValue('comments', newNotes)
    setAddNewCommentFlag(!addNewCommentFlag)
    setNewComment('')
  }

  const clearValues = () => {
    formik.setFieldValue('comments', '')
  }

  const handleClose = () => {
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'showUserQueryModal', value: false }],
    })
  }

  function saveActivity() {
    saveUserQuery()
  }

  return (
    <div>
      <Modal
        headingText={headingText}
        // className="myModal"
        size="dense"
        isVisible={state.showUserQueryModal}
        onRefuse={() => {
          clearValues()
          handleClose()
        }}
      >
        <Grid.Container className="hc-pt-normal hc-pl-dense">
          <Grid.Container className="hc-pa-normal">
            <Grid.Item xs={12}>
              <Card className="hc-pa-normal">
                <Grid.Item xs={12}>
                  <Form.Field
                    type="textarea"
                    placeholder={placeholderText}
                    maxLength="500"
                    value={newComment}
                    onChange={(event) => {
                      setNewComment(event.target.value)
                    }}
                    autoFocus
                  />
                </Grid.Item>
                <Grid.Container direction="row-reverse">
                  <Grid.Item>
                    <Button
                      className="hc-ma-none"
                      type="primary"
                      size="dense"
                      onClick={() => {
                        clearValues()
                        handleClose()
                      }}
                    >
                      CANCEL
                    </Button>
                  </Grid.Item>
                  <Grid.Item>
                    <Button
                      className="hc-ma-none"
                      type="primary"
                      size="dense"
                      disabled={newComment === ''}
                      onClick={() => {
                        dispatch({
                          type: SET_FIELD,
                          payload: [{ id: 'comments', value: true }],
                        })
                        handleAdd()
                        saveActivity()
                        handleClose()
                      }}
                    >
                      OK
                    </Button>
                  </Grid.Item>
                </Grid.Container>
              </Card>
            </Grid.Item>
          </Grid.Container>
        </Grid.Container>
      </Modal>
    </div>
  )
}
