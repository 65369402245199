import Section, { SectionItem } from '../Section'
import { DateFormatter } from '@enterprise-ui/canvas-ui-react-date'

export const ViewDates = ({ dates }) => {
  return (
    <Section labelGridSize={4} detail={false} heading={'Dates'} expandable>
      <SectionItem label={'Created'}>
        <DateFormatter date={dates?.create_ts} format="L" />
      </SectionItem>
      <SectionItem label={'Changed'}>
        {' '}
        <DateFormatter date={dates?.update_ts} format="L" />
      </SectionItem>
    </Section>
  )
}
