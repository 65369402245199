import { useState } from 'react'
import { Divider, Heading, Tabs, Grid } from '@enterprise-ui/canvas-ui-react'
import { BottomBorderDiv } from '../CamsDivComponent'
import { AuditLog } from './AuditLog'
import { Comments } from './Comments'

export const NotesTab = ({
  formik,
  budgetDetails,
  userProfile,
  saveComment,
  setBudgetStatus,
}) => {
  const [activeNotesTab, setActiveNotesTab] = useState('comments')
  return (
    <BottomBorderDiv>
      <Grid.Container className="hc-pl-none hc-pr-none hc-pt-none">
        <Heading size={6}>Activity</Heading>
        <Grid.Container className="hc-pa-dense hc-ma-none">
          <Tabs
            activeTab={activeNotesTab}
            disableAdaptive={true}
            onTabSelect={(event, tab) => {
              setActiveNotesTab(tab.name)
            }}
            className="hc-mt-dense"
          >
            <Tabs.Item name="auditLog">History</Tabs.Item>
            <Tabs.Item name="comments">Comments</Tabs.Item>
            <Divider />
            <Tabs.Content name="auditLog">
              <AuditLog
                auditEntries={budgetDetails?.budget_audit_entries}
              ></AuditLog>
            </Tabs.Content>
            <Tabs.Content name="comments">
              <Comments
                budgetComments={budgetDetails?.budget_comments}
                formik={formik}
                userProfile={userProfile}
                saveComment={saveComment}
                setBudgetStatus={setBudgetStatus}
              />
            </Tabs.Content>
          </Tabs>
        </Grid.Container>
      </Grid.Container>
    </BottomBorderDiv>
  )
}
